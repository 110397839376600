import GoogleTranslateImg from 'icons/google-translate.png';

export const css = `
    div#google_translate_element {
        display: flex;
        justify-content: flex-end;
    }
    
    div#google_translate_element div.goog-te-gadget-simple {
        border: none;
        display: flex;
        flex-direction: row-reverse;
        align-items: center;
    }
    
    div#google_translate_element div.goog-te-gadget-simple img.goog-te-gadget-icon {
        content: url("${GoogleTranslateImg}"); 
        background: none!important;
        width: 52px;height:auto;
    }
`;
