import React from 'react';
import classNames from 'classnames';
import { Field } from 'formik';

import styles from './FieldContainer.css';


const FieldContainer = ({ name, label, isRequired, children }) => (
  <Field name={name}>
    {({ field, meta }) => (
      <div className={classNames(styles.fieldContainer)}>
        <div className={styles.fieldName}>
          {label}
          {isRequired && <span className={styles.requiredFieldIcon}> *</span>}
        </div>
        <div className={styles.fieldContent}>{children(field, meta)}</div>
        {meta.touched && meta.error && (
          <div className={styles.errorMsg}>{meta.error}</div>
        )}
      </div>
    )}
  </Field>
);

FieldContainer.defaultProps = {
  isRequired: false,
};

export default FieldContainer;
