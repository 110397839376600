import React from 'react';
import styles from './IdVerification.css';

type ItemT = {
  name: string;
  value: string;
}

type Props = {
  data: ItemT[];
}

const Item: React.FC<ItemT> = ({ name, value }) => (
  <div className={styles.dataItem}>
    <span className={styles.dataItemName}>{name}</span>
    <span className={styles.dataItemValue}>{value}</span>
  </div>
);

const VerifiedData: React.FC<Props> = ({ data }) => (
  <div>
    <div className={styles.dataHeader}>Verified Data from ID</div>
    <div className={styles.dataContainer}>
      {data.map(item => (
        <Item key={item.name} name={item.name} value={item.value} />
      ))}
    </div>
  </div>
);

export default VerifiedData;
