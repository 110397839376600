import jwtDecode from 'jwt-decode';

export const isLinkExpired = (token: any): boolean => {
  if (!token) return false;
  const decodedToken = jwtDecode(token);
  if (!decodedToken.exp) return false;
  return decodedToken.exp * 1000 < Date.now();
};

export const shouldDisplayDownloadLink = (pdfUrl: string, pdfUrlClicked: boolean, token: string): boolean =>
  Boolean(pdfUrl && !pdfUrlClicked && !isLinkExpired(token));

export const shouldDisplayExpiredLinkMessage = (pdfUrl: string, pdfUrlClicked: boolean, token: string) =>
  Boolean(pdfUrl && (pdfUrlClicked || isLinkExpired(token)));
