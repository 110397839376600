import 'whatwg-fetch';
import { Datacenter, datadogLogs } from '@datadog/browser-logs';
import React from 'react';
import { connect } from 'react-redux';
import { SplitTreatments } from '@splitsoftware/splitio-react';
import { getTreatments } from '@splitsoftware/splitio-redux';
import { deployment } from 'utils/routing';
import injectPermissions from '../decorators/injectPermissions';
import OneNavBar from 'components/OneNavBar';
import Header from './Header';
import { getAuthenticationState } from '../reducers';
import { last } from 'lodash';

// type Route = {
//   childRoutes?: Array<{}>,
//   indexRoute: {},
//   noHeader?: boolean,
//   path: string,
// };

// type Props = {
//   children: React$Element<*>,
//   history: {},
//   isLoggedIn: boolean,
//   location: Location,
//   params: {
//     formID?: string,
//   },
//   routes: Route[],
//   maid: ?string,
// } & UserCapabilityChecks;

// eslint-disable-next-line no-nested-ternary
const ddlogEnv = /(nonprod|localhost)/.test(location.hostname) ? 'nonprod' : (/stage/.test(location.hostname) ? 'stage' : 'prod');
datadogLogs.init({
  clientToken: 'pub99c5e641777c4c912ccc4399aba20626',
  forwardErrorsToLogs: true,
  sampleRate: ddlogEnv === 'prod' ? 100 : 0,
  service: 'ng.client',
  datacenter: Datacenter.EU,
  site: 'datadoghq.eu',
  env: ddlogEnv,
});

interface AppProps {
  params: any,
  location: any,
  history: any,
  routes: any[],
  maid: string,
  getTreatments: Function,
}

class App extends React.Component<AppProps> {
  componentDidMount() {
    this.getSplitTreatments();
  }

  componentDidUpdate(prevProps) {
    if (this.props.maid && this.props.maid !== prevProps.maid) {
      this.getSplitTreatments();
    }
  }

  getSplitTreatments = (): void => {
    this.props.getTreatments({splitNames: [
      'SORT_AND_FILTER_SUBMISSION_MANAGER',
      'FORTE_PAYMENT_DISABLED',
      'NG_VIEW_FORM',
      'LOBBY_SEARCH',
      'SIGNED_BY_ME_FILTER',
      'SM_BULK_ACTIONS',
      'PARALLEL_SIGNERS',
      'SUBMIT_BUTTON_SETTINGS',
    ], attributes: {maid: this.props.maid, deployment}});
  }

  render() {
    const { params, location, history, routes, maid } = this.props;
    const showHeader = !last(routes).noHeader;
    return (
      <SplitTreatments names={['ONE_NAV']} attributes={{ maid, deployment }}>
        {({ treatments: { ONE_NAV }, isReady }) => {
          if (!isReady) {
            return null;
          }

          const isOneNavEnable = ONE_NAV.treatment === 'on';
          (window as any).isOneNavEnable = isOneNavEnable;

          return (
            <div id='ng-app'>
              {process.env.NODE_ENV === 'development' &&
                (() => {
                  const DevTools = require('./DevTools').default;
                  return <DevTools />;
                })()}

              {showHeader && isOneNavEnable && <OneNavBar />}
              {showHeader && !isOneNavEnable && <Header />}

              <div
                style={{
                  display: 'flex',
                  flex: 1,
                }}
              >
                {React.cloneElement(this.props.children as any, { params, location, history })}
              </div>
            </div>
          );
        }}
      </SplitTreatments>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  getTreatments: param => dispatch(getTreatments(param)),
});

const mapStateToProps = state => ({
  isLoggedIn: getAuthenticationState(state),
  maid: state.auth.maid,
});

export default injectPermissions(connect(mapStateToProps, mapDispatchToProps)(App));
