import Cookies from 'js-cookie';
import { WebAuth } from 'auth0-js';
import decode from 'jwt-decode';
import storage from 'utils/storage';
import { uniq, mapKeys, memoize } from 'lodash';
import { parse, stringify } from 'query-string';
import * as env from '../env';
import * as actions from 'actions/auth';
import { tokenTypes } from 'constants/auth';
import * as routes from 'constants/route';
import { userRolesEnum, userCapabilitiesEnum as userCapabilities } from 'constants/user';
import { grmLobby } from 'utils/routing';
import { postFetch } from '../simpleApi/postFetch';
import { authTypes } from 'constants/api';
import { loginInternal } from 'internal-tools/actions/auth';
import { Features } from 'types/features';

// import type { Dispatch } from 'redux';
// import type {
//   PartialCoregistrationProfile,
//   Profile,
//   AuthTokenKey,
//   RegistrationForServer,
//   RegistrationParseState,
//   UserCapabilities,
//   UserCapabilityChecks,
// } from 'types/auth';
// import type { Role } from 'types/shared';
// import type { Connection } from 'types/active-directory';

// This should not be changed without careful consideration and coordination
// with PHP and NG, things will break otherwise
export const JWT_NAMESPACE = 'https://seamlessdocs.com/jwt/v1/';

export function getTokenExpirationDate(token: string) {
  try {
    const decoded = decode(token);
    if (!decoded || !decoded.exp) {
      return null;
    }
    const date = new Date(0); // The 0 here is the key, which sets the date to the epoch
    date.setUTCSeconds(decoded.exp);
    return date;
  } catch (e) {
    return null;
  }
}

export function isTokenExpired(token: string, expirationDate?: Date) {
  const exp = getTokenExpirationDate(token);
  const date = expirationDate || new Date();

  if (!exp) {
    return true;
  }
  return exp.valueOf() <= date.valueOf();
}

const getToken = (tokenKey: string) => storage().getItem(tokenKey);

export const getIdToken = () => getToken(tokenTypes.WithAuth);

export const getCoregistrationToken = () => getToken(tokenTypes.CoregAuth);

export const setLegacyToken = token => {
  Cookies.set('idToken', token, {
    domain: window.location.hostname,
    expires: 1,
    path: '/',
    secure: true,
  });
};

export const setLegacyAUID = auid => {
  Cookies.set('auid', auid, {
    domain: window.location.hostname,
    expires: 1,
    path: '/',
    secure: true,
  });
};

const setTokenPrivate = (token: string, tokenKey: string) => {
  if (isTokenExpired(token)) {
    storage().removeItem(tokenKey);
    return null;
  }
  storage().setItem(tokenKey, token);
  return token;
};

export const setToken = (token: string) => setTokenPrivate(token, tokenTypes.WithAuth);

export const setCoregistrationToken = token => setTokenPrivate(token, tokenTypes.CoregAuth);

const isTokenValid = (tokenKey: string) => {
  const token = getToken(tokenKey);
  if (!token || isTokenExpired(token)) {
    storage().removeItem(tokenKey);
    return false;
  }
  return true;
};

export const isLoggedIn = () => isTokenValid(tokenTypes.WithAuth);

export const hasValidCoregistrationToken = () => isTokenValid(tokenTypes.CoregAuth);

export const getRegistrationForServer = (
  {email, firstName, lastName, password, timezone, phoneNumber}
  :{email: string, firstName: string, lastName: string, password: string, timezone: string, phoneNumber: string}) => {
  const coregistrationForServer = {
    email,
    firstName,
    lastName,
    password,
    timezone,
    phoneNumber,
  };

  return coregistrationForServer;
};

export const setProfile = (profile: any) => {
  if (isLoggedIn()) {
    return storage().setItem('profile', JSON.stringify(profile));
  }
  return null;
};

export const setTokenAndProfile = (token: string, profile: any) => setToken(token) && setProfile(profile);

export const getProfile = () => {
  if (isLoggedIn()) {
    const profile = storage().getItem('profile');
    return profile ? JSON.parse(profile) : null;
  }
  return null;
};

export const getMAID = () => {
  const profile = getProfile();
  return profile && profile.maid;
};

export const getUID = () => {
  const profile = getProfile();

  return profile ? profile.auid : null;
};

export const isAdmin = role => role === userRolesEnum.OWNER;

export const isVisitor = role => !!role && role.toUpperCase() === userRolesEnum.VISITOR;

export const webAuth = new WebAuth({
  domain: env.AUTH0_DOMAIN,
  clientID: env.AUTH0_CLIENT_ID,
  callbackURL: env.AUTH0_CALLBACK_URL,
  callbackOnLocationHash: true,
});

const safeDecode = (idToken: string) => {
  try {
    return decode(idToken);
  } catch (error) {
    return null;
  }
};

const getOrigin = () => {
  const location = window.location;
  if (location.origin) {
    return location.origin;
  }
  // Hack for IE10
  return `${location.protocol}//${location.hostname}${location.port ? ':' + location.port : ''}`;
};

const removeAuthFromLocalStorage = () => {
  storage().removeItem(tokenTypes.WithAuth);
  storage().removeItem(tokenTypes.CoregAuth);
  storage().removeItem('profile');
};

export const logout = (federated, webAuth_ = webAuth) => {
  removeAuthFromLocalStorage();
  const logoutConfig = {
    federated,
    returnTo: `${getOrigin()}${routes.LEGACY_LOGOUT}`,
  };
  if (!logoutConfig.federated) delete logoutConfig.federated;
  webAuth_.logout(logoutConfig);
};

export const removeNamespacesFromProfile = (profile: any) =>
  mapKeys(profile, (value, key) => (key.toString().includes(JWT_NAMESPACE) ? key.slice(JWT_NAMESPACE.length) : key));

const transformProfileFromServer = profile => {
  const profileWithNamespaceRemoved = removeNamespacesFromProfile(profile);
  // auth0 used to give us an 'email' key but we want to use 4 letter keys for everything
  // so transform here to keep backwards compatibility
  profileWithNamespaceRemoved.email = profile.email;
  delete profileWithNamespaceRemoved.emal;
  return profileWithNamespaceRemoved;
};

export const setProfileFromToken = (
  idToken: string, uid: string, maid: string, subdomain: string, dispatch: Function, overwriteProfile?: boolean) => {
  const profile = safeDecode(idToken);
  if (profile) {
    const p = overwriteProfile
      ? { ...profile, uid, auid: uid, maid, subd: subdomain }
      : { uid, auid: uid, maid, subd: subdomain, ...profile };
    const clientProfile = transformProfileFromServer(p);
    dispatch(actions.auth0LoginSuccess(idToken, clientProfile));
  } else {
    dispatch(actions.loginError(new Error("Can't decode user profile.")));
    dispatch(actions.authorizationCheckFailure());
    dispatch(actions.requestLogout());
  }
};

const translateAuth0Error = (error: any) => {
  switch (error?.status) {
    case 401:
      return 'Wrong email or password';
    case 410:
      return 'User disabled, please contact account admin for access.';
    case 412:
      return 'Your email is not verified. Please verify your email or ask your account admin for help.';
    case 429:
      return 'You have tried logging in too many times. You have been temporarily locked out. Please try again later';
    default:
      return 'Login failed, please try again';
  }
};

type LoginObject = {
  idToken?: string,
  uid?: string,
  maid?: string,
  subdomain?: string,
}

export const fakeLogin = (idToken: string, maid: string, domain: string, userId: string, dispatch: Function) => {
  setProfileFromToken(idToken, userId, maid, domain, dispatch, true);
};

export const handleLogin = dispatch => (error, { idToken, uid, maid, subdomain }: LoginObject = {}) => {
  if (error) {
    dispatch(actions.loginError(translateAuth0Error(error)));
  } else if (idToken) {
    const isSubdomainNullOrEmpty = !subdomain;
    // @ts-ignore
    setProfileFromToken(idToken, uid, maid, subdomain, dispatch, !isSubdomainNullOrEmpty);
  } else {
    dispatch(actions.loginError(new Error('Invalid email or password')));
  }
};

// type Options = {
//   password: string,
//   subdomain: string,
//   username: string,
// };

export const buildRedirectUri = (queryArgs = {}) => {
  const { hash, search } = window.location;
  const updatedSearch = '?' + stringify(Object.assign(parse(search), queryArgs));
  return getOrigin() + routes.AUTH0_CALLBACK + updatedSearch + hash;
};

export const signIn = (options: any, dispatch: Function) => {
  dispatch(actions.requestLogin());
  const search = parse(window.location.search);
  const redirectUrl = typeof search.redirect_url === 'string' ? search.redirect_url : undefined;
  dispatch(
    actions.requestUsernamePasswordLogin(options.username, options.password, options.subdomain, redirectUrl, dispatch)
  );
};

export const sendResetPasswordEmail = (email: string, maid: string, dispatch: Function) => {
  dispatch(actions.sendResetPasswordEmail(email, maid));
};

export const resetPassword = (password: string, rawToken: string, dispatch: Function) => {
  dispatch(actions.resetPassword(password, rawToken, dispatch));
};

export const sendVerifyEmail = (email: string, token: string, dispatch: Function) => {
  dispatch(actions.sendVerifyEmail(email, token));
};

export const verifyEmail = (token: string, dispatch: Function) => {
  dispatch(actions.verifyEmail(token, dispatch));
};

export const processLoginError = memoize(error => error ? error?.message || error : '');

const mergeActiveDirectoryScopeWithInternalScope = (scope: string) =>
  uniq(env.AUTH0_SCOPE.split(' ').concat(scope.split(' '))).join(' ');

export const activeDirectorySignIn = ({ name, scope }, dispatch: Function, nonce?: string) => {
  const options = {
    connection: name,
    scope: mergeActiveDirectoryScopeWithInternalScope(scope),
    responseType: tokenTypes.WithAuth,
    redirectUri: buildRedirectUri({ active_directory: 'true' }),
  };
  webAuth.authorize(nonce ? { ...options, nonce } : options);
};

export const getSubdomain = (hostname?: string) => {
  if (process.env.SEAMLESSDOCS_ACCOUNT_KEY) {
    return process.env.SEAMLESSDOCS_ACCOUNT_KEY;
  }
  const hostnameSplitArray = !!hostname ? hostname.split('.') : window.location.hostname.split('.');
  const arrayLength = hostnameSplitArray.length;
  const lastItem = hostnameSplitArray[arrayLength - 1];
  const secondToLastItem = hostnameSplitArray[arrayLength - 2];
  const thirdToLastItem = hostnameSplitArray[arrayLength - 3];
  const firstItem = hostnameSplitArray[0];
  if (secondToLastItem === 'seamlessdocs' && lastItem === 'com' && arrayLength > 2) {
    return firstItem;
  }
  if (thirdToLastItem === 'services' && secondToLastItem === 'govos' && lastItem === 'com' && arrayLength > 3) {
    return firstItem;
  }
  if (secondToLastItem === 'publicforms' && lastItem === 'us' && arrayLength > 2) {
    return firstItem;
  }
  return null;
};

export const signIntoInternalToolingWithGoogle = (dispatch: Function) => {
  dispatch(actions.requestLogin());

  new WebAuth({
    domain: env.INTERNAL_AUTH0_DOMAIN,
    clientID: env.INTERNAL_AUTH0_CLIENT_ID,
  }).popup.authorize(
    {
      connection: 'google-oauth2',
      scope: env.AUTH0_SCOPE,
      redirectUri: env.AUTH0_CALLBACK_URL,
      responseType: tokenTypes.WithAuth,
      owp: true, // necessary to autoclose popup
    },
    handleLogin(dispatch)
  );
};

export const signOutOfInternalTooling = () => {
  removeAuthFromLocalStorage();
  window.location.href = '/';
};

export const tailorLogin = (email: string, password: string, dispatch: Function) => {
  dispatch(actions.requestLogin());
  dispatch(loginInternal(email, password, dispatch));
};

export const generateCapabilitiesGetters = memoize(capabilitiesFromServer => {
  const capabilitiesSet = new Set(capabilitiesFromServer);
  const canUserCreateForms = () => capabilitiesSet.has(userCapabilities.CREATE_FORMS);
  const canUserAccessSupport = () => capabilitiesSet.has(userCapabilities.ACCESS_SUPPORT);
  const canUserAccessAccountDetails = () => capabilitiesSet.has(userCapabilities.ACCESS_ACCOUNT_DETAILS);
  const canUserAccessFolderTree = () => capabilitiesSet.has(userCapabilities.ACCESS_FOLDER_TREE);
  const canUserEditFolderTree = () => capabilitiesSet.has(userCapabilities.ACCESS_EDIT_FOLDER_TREE);
  const canUserAccessTags = () => capabilitiesSet.has(userCapabilities.ACCESS_TAGS);
  const canUserAccessUserList = () => capabilitiesSet.has(userCapabilities.ACCESS_USER_LIST);
  const canUserDeleteSubmissions = () => capabilitiesSet.has(userCapabilities.DELETE_SUBMISSIONS);
  const canUserExportSubmissions = () => capabilitiesSet.has(userCapabilities.EXPORT_SUBMISSIONS);
  const canUserAccessGRM = () => capabilitiesSet.has(userCapabilities.ACCESS_GRM);
  const canUserAccessApiKeys = () => capabilitiesSet.has(userCapabilities.ACCESS_API_KEYS);
  const canUserAccessSD = () => capabilitiesSet.has(userCapabilities.ACCESS_SD);
  const canUserReadCompletedSubmissions = () => capabilitiesSet.has(userCapabilities.READ_COMPLETED_SUBMISSIONS);
  const canUserReadAssignedSubmissions = () => capabilitiesSet.has(userCapabilities.READ_ASSIGNED_SUBMISSIONS);
  const canUserReadArchivedForms = () => capabilitiesSet.has(userCapabilities.READ_ARCHIVED_FORMS);
  const canUserReadSubmissionDetails = () => capabilitiesSet.has(userCapabilities.READ_SUBMISSION_DETAILS);

  return {
    canUserCreateForms,
    canUserAccessSupport,
    canUserAccessAccountDetails,
    canUserAccessFolderTree,
    canUserEditFolderTree,
    canUserAccessTags,
    canUserAccessUserList,
    canUserDeleteSubmissions,
    canUserExportSubmissions,
    canUserAccessGRM,
    canUserAccessApiKeys,
    canUserAccessSD,
    canUserReadCompletedSubmissions,
    canUserReadAssignedSubmissions,
    canUserReadArchivedForms,
    canUserReadSubmissionDetails,
  };
});

export const redirectToLandingForVisitors = (features?: Features) => {
  const profile = getProfile();

  if ((window as any).isOneNavEnable && profile && isVisitor(profile.role)
    && (features?.GRM_WITH_SC || features?.GRM_WITHOUT_SC)) {
    window.location.href = grmLobby();
  }
};

export async function requestPair(targetMaid: string, email: string) {
  let result = null;
  try {
    const response = await postFetch({
      endpoint: 'users/associateUser.json',
      data: {targetMaid, email},
      auth: authTypes.NoAuth,
    });
    if (response.ok) {
      result = await response.json();
    } else {
      const err = new Error('Pairing failed.');
      err.name = 'PairingError';
      throw err;
    }
  } catch (error) {
    return error;
  }
  return result;
}

export async function getMasterAccountsByEmail(email: string, maid: string) {
  let result: any = null;
  try {
    const response = await postFetch({
      endpoint: 'masteraccount/byEmail.json',
      data: {email, maid},
      auth: authTypes.NoAuth,
    });
    if (response.ok) {
      result = await response.json();
    } else {
      const err = new Error('Failed to get master accounts');
      throw err;
    }
  } catch (error) {
    return error;
  }
  return result?.result;
}

export const removeLineBreaks = (value: string): string => value.replace(/\r?\n|\r/gm, '');
