// import typeof {LOG_SUBMISSION_NAVIGATION} from 'constants/types/routingActionTypes';
export const FROM_TOOLBAR = 'Toolbar';
export const FROM_CELL = 'Submission Number';
// export type SubmissionNavigationSource = typeof FROM_TOOLBAR | typeof FROM_CELL;

// export type LogSubmissionNavigationAction = {
//   source: SubmissionNavigationSource,
//   type: LOG_SUBMISSION_NAVIGATION,
// };

// export type LogSubmissionNavigationActionCreator =
//   (source: SubmissionNavigationSource) => LogSubmissionNavigationAction;
