import React from 'react';
import { connect } from 'react-redux';
import NewAuthLayout from '../../components/AuthLayout/NewAuthLayout';
import * as authActions from 'actions/auth';
import { sendResetPasswordEmail } from 'utils/auth';


import { Link } from 'react-router';


const INVALID_EMAIL = 'A valid email address is required.';

class ForgotPassword extends React.Component<any, any> {
  constructor(props) {
    super(props);
    this.state = {
      email: props.profile?.email || '',
      inputError: '',
      submitAttempt: 0,
    };
  }

  componentDidMount() {
    const { dispatch, maid } = this.props;
    if (!maid) { // if we have a maid, the state has already been filled
      dispatch(authActions.getAccountKey(dispatch));
    }
  }

  sendResetEmail = event => {
    event.preventDefault();
    const { dispatch, maid } = this.props;
    const form = event.target;
    const nState = {
      submitAttempt: this.state.submitAttempt + 1,
    };
    if (form.checkValidity()) {
      this.setState({
        ...nState,
        inputError: '',
      });
      sendResetPasswordEmail(this.state.email, maid, dispatch);
    } else {
      this.setState({
        ...nState,
        inputError: INVALID_EMAIL,
      });
    }
  }

  emailChanged = event => {
    event.preventDefault();
    const { submitAttempt } = this.state;
    const { sendResetPasswordError } = this.props;
    const overrideSubmitError = (sendResetPasswordError ? ' ' : ''); // need to find a less hacky way to ignore a prop
    const nState = {
      email: event.target.value,
      inputError: submitAttempt && !event.target.validity.valid ? INVALID_EMAIL : overrideSubmitError,
    };
    this.setState(nState);
  };

  renderResetRequestForm() {
    const {
      sendResetPasswordError,
      isFetching,
    } = this.props;
    const { email, inputError } = this.state;
    const errMsg = inputError || sendResetPasswordError || '';
    const cssInvalid = errMsg.trim() ? 'invalid' : ''; // need to find a less hacky way to ignore a prop
    return (
      <form onSubmit={this.sendResetEmail} noValidate>
        <div style={{ textAlign: 'left' }}>
          <label htmlFor='email'>Email</label><br />
          <input autoFocus className={cssInvalid} value={email} tabIndex={1} onChange={this.emailChanged} type='email' id='email' name='email' placeholder='Enter your account email.' required />
          <div className='inputError'>{errMsg}</div>
        </div>
        <br />
        <button className='authButton' tabIndex={2} disabled={isFetching}>Send Reset Email</button>
      </form>
    );
  }

  renderSuccessMessage() {
    return (
      <p>
        A reset password email has been successfully sent if an account exists with that email.<br />
        Please allow a few minutes for its arrival and follow its instructions.
      </p>);
  }

  redirect(url) {
    window.location = url;
  }

  render() {
    const {
      isFetchingOrganizationInfo,
      isSubdomainExists,
      companyName,
      organizationLogo,
      sendResetPasswordSuccess,
      profile,
    } = this.props;
    const { submitAttempt } = this.state;
    const subtitle = 'Enter the email address to your account and we\'ll send you a link to reset your password.';
    const expiredMsg = !submitAttempt && location.href.endsWith('/expired') ? 'Your reset link has expired.' : null;
    return (
      <NewAuthLayout
        title='Reset Your Password'
        subtitle={sendResetPasswordSuccess ? '' : subtitle}
        fetching={isFetchingOrganizationInfo || !isSubdomainExists}
        companyName={companyName}
        logo={organizationLogo}
        error={expiredMsg}
      >
        <div>
          <div className='sub-container'>
            {sendResetPasswordSuccess ? this.renderSuccessMessage() : this.renderResetRequestForm()}
            {profile ?
              <Link to='/profile' onClick={() => this.redirect('/profile')}>Back to Account</Link> :
              <Link to='/ng/login'>Back to Login</Link>
            }
          </div>
        </div>
      </NewAuthLayout >
    );
  }
}


const mapStateToProps = state => {
  const {
    auth: {
      accountKey,
      companyName,
      error,
      isFetching,
      isFetchingOrganizationInfo,
      isSubdomainExists,
      maid,
      organizationLogo,
      sendResetPasswordError,
      sendResetPasswordSuccess,
      profile,
    },
  } = state;
  return {
    accountKey,
    companyName,
    error,
    isFetching,
    isFetchingOrganizationInfo,
    isSubdomainExists,
    maid,
    organizationLogo,
    sendResetPasswordError,
    sendResetPasswordSuccess,
    profile,
  };
};

export default connect(mapStateToProps)(ForgotPassword);
