type FormatTableDataFromJSONTypes = {
  folderId: string,
  tableData: any[],
}

export function formatTableDataFromJSON({folder, forms}: {folder:string, forms: any[]}): FormatTableDataFromJSONTypes {
  const tableData = forms || [];
  return {
    folderId: folder,
    tableData,
  };
}

export function createTableFromSearchJSON({searchResults}: { searchResults: any[] }): FormatTableDataFromJSONTypes {
  const tableData = searchResults || [];
  return formatTableDataFromJSON({
    folder: '',
    forms: tableData,
  });
}
