import React, { useState, useEffect, useCallback } from 'react';
import { bindActionCreators } from 'redux';
import ReactHtmlParser from 'react-html-parser';
import {connect} from 'react-redux';
import IconButton from '@material-ui/core/IconButton';
import MailIcon from '@material-ui/icons/Mail';
import { Link } from 'react-router';
import jwtDecode from 'jwt-decode';
import {
  shareSubmissionFromThankYouPage as shareSubmissionFromThankYouPageAction,
  toggleIsShareModalOpen as toggleIsShareModalOpenAction,
  getThankYouPagePDFUrl as getThankYouPagePDFUrlAction,
} from 'actions/forms';
import {
  getIsThankYouPageShareModalOpen,
  getIsShareSubmissionLoading,
  getShareSubmissionError,
  getThankYouPagePdfUrl,
} from 'reducers/index';
import ShareModal from './ShareModal';
import style from 'styles/shared/containerStyles.css';
import formStyle from './FormLiveView.css';
import {shouldDisplayDownloadLink, shouldDisplayExpiredLinkMessage} from 'utils/formLiveView/thankYouPageHelpers';

type PropsFromState = {
  disableDownloadAndShareButtons: boolean,
  formId: string,
  formName: string,
  header: string,
  isShareModalOpen: boolean,
  isShareSubmissionLoading: boolean,
  maid: string,
  message: string,
  shareSubmissionError: string | null,
  pdfUrl: string | null,
};

type PropsFromDispatch = {
  shareSubmissionFromThankYouPage: typeof shareSubmissionFromThankYouPageAction,
  toggleIsShareModalOpen: typeof toggleIsShareModalOpenAction,
  getThankYouPDFUrl: typeof getThankYouPagePDFUrlAction,
};

type Props =
 & PropsFromState
 & PropsFromDispatch
 & {
  pdfUrl: string,
  params: any,
};

function ThankYouPage({
  disableDownloadAndShareButtons,
  formId = '',
  formName = '',
  header = 'Thank you!',
  isShareModalOpen = false,
  isShareSubmissionLoading = false,
  maid = '',
  message = 'Your submission is complete.',
  params,
  pdfUrl = '',
  shareSubmissionError = '',
  shareSubmissionFromThankYouPage,
  toggleIsShareModalOpen,
  getThankYouPDFUrl,
}: Props) {
  const { token } = params;
  const [thankYouToken, setThankYouToken] = useState<{exp: number} | null>(null);
  const [pdfUrlClicked, setPdfUrlClicked] = useState<boolean>(false);


  const MAX_ATTEMPTS = 10; // 10 MAX_ATTEMPTS to get the PDF URL
  const DELAY = 1250; // 1.25 seconds DELAY between each attempt
  useEffect(() => {
    let attempts = 0;
    const intervalPDFPolling = setInterval(() => {
      if (pdfUrl || attempts >= MAX_ATTEMPTS) {
        clearInterval(intervalPDFPolling);
      } else {
        attempts++;
        getThankYouPDFUrl(token); // Dispatch action to update the Redux state
      }
    }, DELAY);

    return () => clearInterval(intervalPDFPolling); // Cleanup on component unmount
  }, [pdfUrl, token, getThankYouPDFUrl]);

  useEffect(() => {
    if (token && !thankYouToken) {
      try {
        const t = jwtDecode(token);
        t.exp = t.exp * 1000;
        setThankYouToken(t);
      } catch (error) {
        setThankYouToken({ exp: 0 });
      }
    }
  }, [thankYouToken]);


  const mailClicked = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    toggleIsShareModalOpen(true);
  }, [toggleIsShareModalOpen]);


  const pdfLinkClicked = useCallback((): void => {
    if (pdfUrl) {
      setPdfUrlClicked(true);
    }
  }, [pdfUrl]);

  return (
    <div className={`${style.Container} ${formStyle.FormLiveViewContainer}`}>
      {isShareModalOpen &&
        <ShareModal
          closeModal={() => toggleIsShareModalOpen(false)}
          formId={formId}
          formName={formName}
          isLoading={isShareSubmissionLoading}
          isOpen={isShareModalOpen}
          maid={maid}
          shareSubmissionError={shareSubmissionError}
          shareSubmissionFromThankYouPage={shareSubmissionFromThankYouPage}
          token={token}
        />}
      <div className={formStyle.FormLiveViewSubContainer}>
        <div className={formStyle.FormThankYou}>
          {!disableDownloadAndShareButtons && (
            <IconButton
              onClick={mailClicked}
              className={formStyle.mailButton}
              title='Email a copy of your completed document'
              aria-label='Email a copy of your completed document'
            >
              <MailIcon />
            </IconButton>
          )}
          <br />
          <h1 className={formStyle.thankYouPageHeader}>{header}</h1>
          <h2 className={formStyle.thankYouPageMessage}>{ReactHtmlParser(message)}</h2>
          {shouldDisplayDownloadLink(pdfUrl, pdfUrlClicked, token) && (
            <a
              className={formStyle.pdfUrlLink}
              href={pdfUrl}
              target='_blank'
              rel='noopener noreferrer'
              onClick={pdfLinkClicked}
              title={'This link will expire after it\'s  clicked.'}
              aria-label='Download your PDF'
            >
              Click here to download your PDF. The link will expire after you click it.
            </a>
          )}
          {shouldDisplayExpiredLinkMessage(pdfUrl, pdfUrlClicked, token) && (
            <>
              <h2 className={formStyle.thankYouPageMessage}>Download link expired</h2>
              <p>Please <Link to='/ng/login'>log in</Link> to view your submission.</p>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

const mapStateToProps = (state): PropsFromState => {
  const form = state?.forms?.liveView?.form?.form;
  return {
    disableDownloadAndShareButtons: form?.formSettings?.isDownloadAndShareDisabled || false,
    formId: form?.formId,
    formName: form?.formName,
    header: form?.formSettings?.thankYouCustomSettings?.header,
    isShareModalOpen: getIsThankYouPageShareModalOpen(state),
    isShareSubmissionLoading: getIsShareSubmissionLoading(state),
    maid: state?.auth?.maid || '',
    message: form?.formSettings?.thankYouCustomSettings?.body,
    shareSubmissionError: getShareSubmissionError(state),
    pdfUrl: getThankYouPagePdfUrl(state),
  };
};

const mapDispatchToProps = (dispatch): PropsFromDispatch => ({
  shareSubmissionFromThankYouPage: bindActionCreators(shareSubmissionFromThankYouPageAction, dispatch),
  toggleIsShareModalOpen: bindActionCreators(toggleIsShareModalOpenAction, dispatch),
  getThankYouPDFUrl: bindActionCreators(getThankYouPagePDFUrlAction, dispatch),
});

export default connect(mapStateToProps, mapDispatchToProps)(ThankYouPage);
