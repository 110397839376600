import React, { forwardRef, useEffect, useImperativeHandle, useState, useRef } from 'react';
import classNames from 'classnames';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import {buildFormCompStyle} from 'utils/css';
import style from './FormMultiline.css';
import sharedStyle from '../shared.css';
import Tooltip from 'components/Tooltip';
import { ControlState, LabelAlignment, ShowLabelValue, ReadOnlyValue } from 'types/liveView';
import { getAlignmentClass, showLabelToBoolean, readonlyToBoolean } from 'utils/formLiveView/formLiveView';


type Props = {
  id: string,
  label?: string,
  tooltip?: string,
  fieldState?: ControlState | null,
  value?: string,
  name?: string,
  onChange?: Function | undefined,
  updateForm?: Function | undefined,
  cssClass?: string,
  labelCssClass?: string,
  textareaCssClass?: string,
  validationType?: string,
  showHideCondition?: any,
  extraData?: {
    height: string,
    label?: string,
    labelAlign: LabelAlignment,
    maxlength: string,
    placeholder?: string,
    readonly?: ReadOnlyValue,
    showLabel?: ShowLabelValue,
    width?: string,
    defaultValue?: string,
  },
  properties?: any,
  required?: boolean,
  hoverText?: string,
  isDisabled: boolean,
};

function FormMultiLine({
  id,
  label = '',
  tooltip = '',
  fieldState = null,
  value = '',
  name = '',
  onChange = undefined,
  updateForm = undefined,
  cssClass = style.FormMultilineControlGroup,
  labelCssClass = style.FormTextareaLabel,
  textareaCssClass = style.FormTextarea,
  validationType,
  showHideCondition,
  extraData = {
    maxlength: '',
    height: '',
    showLabel: 't',
    labelAlign: 'auto',
    readonly: 'f',
  },
  properties = {},
  required = false,
  isDisabled = false,
  hoverText = '',
  ...props
}: Props & Partial<any>, ref) {
  const val: string = value || fieldState?.fields?.multiline || '';
  const [touched, setTouched] = useState<boolean>(false);
  const [error, setError] = useState<string>('');
  const inputRef = useRef<HTMLTextAreaElement>(null);
  const { height, maxlength, placeholder } = extraData;

  useEffect(() => {
    if (!!extraData?.defaultValue && !val) {
      updateForm && updateForm({
        fields: { multiline: extraData.defaultValue },
        calculations: extraData.defaultValue,
      });
    }
  }, [extraData]);

  const selfValidate = () => {
    if (required && !val) {
      setError('This field is required.');
      return false;
    }

    setError('');
    return true;
  };

  useEffect(() => {
    touched && selfValidate();
  }, [val]);

  useImperativeHandle(
    ref,
    () => ({
      focus: () => inputRef.current?.focus(),
      validate: selfValidate,
    }));

  const handleChange = event => {
    event.preventDefault();
    setTouched(true);
    const v = event.target.value;
    onChange && onChange(event);
    updateForm && updateForm({
      fields: { multiline: v },
      calculations: v,
    });
  };
  const CustomCompStyle = buildFormCompStyle(id, properties);

  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}>
      <div className={classNames(cssClass, 'single_textarea_container')} id={`form_multiline_${id}`} data-tooltip={!!tooltip}>
        <CustomCompStyle />
        { showLabelToBoolean(extraData?.showLabel) && <label
          aria-label={label}
          htmlFor={id}
          className={classNames(labelCssClass, { [sharedStyle.Required]: required }, 'field_label', getAlignmentClass(extraData?.labelAlign, sharedStyle))}>
          {label}
        </label> }
        <ErrorContainer error={error}>
          <textarea
            id={id}
            className={classNames(textareaCssClass, 'field_input', `${id}-font-settings`)}
            value={val}
            name={name || id}
            onChange={handleChange}
            placeholder={placeholder || ''}
            readOnly={readonlyToBoolean(extraData?.readonly)}
            maxLength={Number.parseInt(maxlength, 10)}
            ref={inputRef}
            style={height ? { height: `${height}px`} : {}}
            required
            disabled={isDisabled}
            {...props} />
        </ErrorContainer>
      </div>
    </Tooltip>
  );
}

export default React.memo(forwardRef(FormMultiLine));
