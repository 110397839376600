import { SHARE_SUBMISSION_FROM_THANK_YOU_PAGE, TOGGLE_SHARE_MODAL, GET_THANK_YOU__PAGE_PDF_URL } from 'constants/types/formActionTypes';
import api from 'reducers/api';

export type State = {
  isShareModalOpen: boolean;
  isShareSubmissionLoading: boolean;
  shareSubmissionError: string | null;
  pdfUrl: string | null;
  errorMessage?: string;
};

const initialState: State = {
  isShareModalOpen: false,
  isShareSubmissionLoading: false,
  shareSubmissionError: null,
  pdfUrl: null,
};

export default (state: State = initialState, action_) => {
  const action = action_;
  switch (action.type) {
    case TOGGLE_SHARE_MODAL:
      return {
        ...state,
        isShareModalOpen: action?.payload?.isShareModalOpen || !state.isShareModalOpen,
      };
    case SHARE_SUBMISSION_FROM_THANK_YOU_PAGE:
      return api(action, state, {
        pending: () => ({
          ...state,
          isShareSubmissionLoading: true,
          shareSubmissionError: null,
        }),
        success: () => ({
          ...state,
          isShareModalOpen: false,
          isShareSubmissionLoading: false,
          shareSubmissionError: null,
        }),
        failure: () => {
          const { payload: { error } } = action;
          return {
            ...state,
            isShareSubmissionLoading: false,
            shareSubmissionError: error?.message || 'An error occurred while sharing the submission.',
          };
        },
      });
    case GET_THANK_YOU__PAGE_PDF_URL:
      return api(action, state, {
        pending: () => ({
          ...state,
        }),
        success: () => ({
          ...state,
          pdfUrl: action.payload.result,
        }),
        failure: () => {
          const { payload: { error } } = action;
          return {
            ...state, errorMessage: error?.message || 'An error occurred while fetching the PDF.',
          };
        },
      });
    default:
      return state;
  }
};

export const getIsShareModalOpen = (state: State): boolean => state.isShareModalOpen;

export const getIsShareSubmissionLoading = (state: State): boolean => state.isShareSubmissionLoading;

export const getShareSubmissionError = (state: State): string | null => state.shareSubmissionError;

export const getPdfUrl = (state: State): string | null => state.pdfUrl;
