import React from 'react';
import classNames from 'classnames';
import Dialog from '@material-ui/core/Dialog';
import styles from './FormLiveView.css';
import DialogContent from '@material-ui/core/DialogContent';
import Loader from 'components/Loader';

interface Props {
  open: boolean,
  message?: string,
}

function ProcModal({message = 'Processing...', open}: Props) {
  return (
    <Dialog
      classes={{
        root: classNames(styles.PaymentModalRoot, 'form_pay_modal_container'),
      }}
      open={open}
      disableBackdropClick
      disableEscapeKeyDown
    >
      <DialogContent>
        <Loader />
        <div style={{textAlign: 'center', minWidth: 400, padding: '3rem'}}>
          <div style={{marginTop: '1rem', fontSize: '2rem'}}><strong>{message}</strong></div>
          <br />
          <div>IMPORTANT: Please do not close or refresh this window.</div>
        </div>
      </DialogContent>
    </Dialog>
  );
}

export default React.memo(ProcModal);
