import * as React from 'react';
import style from './ErrorContainer.css';
import classNames from 'classnames';

type Props = {
  error: string,
  children: React.ReactNode,
  id?: string,
}

const ErrorContainer = ({children, error, id}: Props) => (
  <div className={classNames({[style.ErrorContainer]: !!error}, 'error_container')}>
    {children}
    <div
      className={classNames(style.Error, {[style.hide]: !error})}
      id={id}
      aria-live='assertive'
      aria-atomic
      role='alert'
    >{error}</div>
  </div>
);

export default React.memo(ErrorContainer);
