import React from 'react';
import MaterialUITooltip from '@material-ui/core/Tooltip';
import styles from './Tooltip.css';
import { joinClassNames } from 'utils/strings';

interface Props {
  children?: React.ReactNode,
  className?: string,
  disabled?: boolean,
  placement?: string,
  title?: string | JSX.Element,
}
export default class Tooltip extends React.Component<Props & Partial<any>, any> {
  static defaultProps = { className: '', placement: 'bottom' }
  state = { arrow: null }

  handleArrow = node => {
    this.setState({ arrow: node });
  }

  render() {
    const {
      children,
      className,
      disabled,
      placement,
      title,
      ...props
    } = this.props;

    if (disabled) return children;

    return (
      <MaterialUITooltip
        title={
          <React.Fragment>
            {/* @ts-ignore */}
            <div className={joinClassNames(styles.arrow, styles[placement])} ref={this.handleArrow} />
            {title}
          </React.Fragment>
        }
        placement={placement}
        classes={{
          tooltip: joinClassNames(styles.tooltipLabel, className),
          tooltipPlacementBottom: styles.tooltipPlacementBottom,
        }}
        PopperProps={
          { popperOptions: { modifiers: { arrow: { enabled: !!this.state.arrow, element: this.state.arrow } } } }
        }
        {...props as any}>
        {children}
      </MaterialUITooltip>
    );
  }
}
