import {Observable} from 'rxjs/Observable';
import {NEW_TABLE_SEARCH_API, UPDATE_TABLE_SEARCH_API} from 'constants/types/submissionsTableActionTypes';
import {success} from './utils/filters';
import {getUsersFromAssignments} from 'actions/assignments';
import {getTags} from 'actions/tags';
import {getStagesOfForm} from 'actions/stages';
import {
  getAssigneesOfSubmissions,
  getPermissionsOfSubmissions,
  getStageOfSubmissions,
  getTagsOfSubmissions,
  getPaymentsOfSubmissions,
  getGovOsPaymentsOfSubmissions,
} from 'actions/submissionsManager/tableApi';
import {getPerFormCapabilities} from 'actions/lobby/tableApiActions';
import {
  QUICK_FILTER_INITIAL_SUBMISSION_LOADS,
  QUICK_FILTER_SUBSEQUENT_SUBMISSION_LOADS,
} from 'constants/quickFilters';
import {ifFeature} from './features';


export default function submissionManagerSideloadEpic(
  action$,
  store,
) {
  const tableAction$ = action$
    .ofType(
      NEW_TABLE_SEARCH_API,
      UPDATE_TABLE_SEARCH_API,
      ...QUICK_FILTER_INITIAL_SUBMISSION_LOADS,
      ...QUICK_FILTER_SUBSEQUENT_SUBMISSION_LOADS,
    )
    .filter(success)
    .map(action => {
      const state = store.getState().submissionTable;
      return {
        submissionIds: action.payload.records.map(record => record.submissionId),
        formId: state.formId,
        isArchivedPage: state.isArchiveView,
      };
    });

  const permissionsAction$ = tableAction$.mergeMap(action =>
    Observable.of(getPermissionsOfSubmissions(action.submissionIds)),
  );

  const assignmentsAction$ = tableAction$
    .letBind(ifFeature(action$, 'ASSIGNMENTS_IN_SUBMISSION_MANAGER_SUPPORT'))
    .mergeMap(action =>
      Observable.of(getAssigneesOfSubmissions(action.submissionIds)).merge(
        Observable.of(getUsersFromAssignments()).throttleTime(10000),
      ),
    );

  const tagAction$ = tableAction$
    .letBind(ifFeature(action$, 'TAGS_IN_SUBMISSION_MANAGER_SUPPORT'))
    .mergeMap(action =>
      Observable.of(getTagsOfSubmissions(action.submissionIds)).merge(Observable.of(getTags()).throttleTime(10000)),
    );

  const stageAction$ = tableAction$
    .letBind(ifFeature(action$, 'STAGES_IN_SUBMISSION_MANAGER_SUPPORT'))
    .mergeMap(action =>
      Observable.of(
        getStageOfSubmissions(action.submissionIds),
        getStagesOfForm(action.formId, action.isArchivedPage),
        getPerFormCapabilities([action.formId]),
      ),
    );

  const paymentAction$ = tableAction$.mergeMap(action => Observable.of(getPaymentsOfSubmissions(action.submissionIds)));

  const govOsPaymentAction$ = tableAction$
    .letBind(ifFeature(action$, 'GOVOS_PAY'))
    .mergeMap(action => Observable.of(getGovOsPaymentsOfSubmissions(action.submissionIds)));


  return assignmentsAction$
    .merge(tagAction$)
    .merge(stageAction$)
    .merge(permissionsAction$)
    .merge(paymentAction$)
    .merge(govOsPaymentAction$);
}
