import React, { memo } from 'react';
import styles from '../IdVerification.css';

const Loader = () => (
  <div className={styles.overlay} role='status' aria-live='polite' aria-busy='true'>
    <div className={styles.personaContainer}>
      <div className={styles.loaderContainer}>
        <div className={styles.loader} />
        <span className={styles.loaderText}>Loading...</span>
      </div>
    </div>
  </div>
);

export default memo(Loader);
