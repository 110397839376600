import React, { useState, useRef, useImperativeHandle, forwardRef, useEffect } from 'react';
import classNames from 'classnames';
import style from './FormInput.css';
import sharedStyle from '../shared.css';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import validate from '../../internal-tools/utils/validator';
import {buildFormCompStyle} from 'utils/css';
import { COMPANY_DOMAIN } from 'constants/emailAddresses';
import Tooltip from 'components/Tooltip';
import { getAlignmentClass, getMaxValue, showLabelToBoolean } from 'utils/formLiveView/formLiveView';
import { LabelAlignment, ShowLabelValue } from 'types/liveView';

const defaultPlaceholders = {
  text: '',
  tel: 'xxx-xxx-xxxx',
  url: 'https://example.com/',
  password: '**********',
  number: '0',
  email: `myself@${COMPANY_DOMAIN}`,
  dollar: '$0.00',
};

const validationMessages = {
  email: 'Please enter a valid email.',
  phone: 'Please enter a valid phone.',
  number: 'Please enter a valid number',
  digit: 'Please enter a valid integer.',
  alpha: 'This field accepts alphabetic characters only.',
  alphanum: 'This field accepts alphanumeric characters only.',
  dollar: 'Please enter a valid dollar amount',
  url: 'Please enter a valid URL. This value must start with either http or https and end with a valid extension.',
};

interface Props {
  type?: string,
  id: string,
  label?: string,
  fieldState: any,
  value?: string,
  name?: string,
  onChange?: Function,
  updateForm?: Function,
  cssClass?: string,
  labelCssClass?: string,
  inputCssClass?: string,
  validationType: string,
  showHideCondition: any,
  extraData?: {
    defaultValue?: string,
    height: string,
    label?: string,
    labelAlign: LabelAlignment,
    maxlength: string,
    placeholder?: string,
    readonly?: string,
    showLabel?: ShowLabelValue,
    validationErrorMessage?: string,
    validationRegex?: string,
    validationregex?: string,
    width: string,
    value?: string,
  },
  specialSettings?: {
    increments: string,
    maxLength: string,
    maximumNumber: string,
    minimumNumber: string,
    placeholder: string,
  },
  isDisabled?: boolean,
  hoverText?: string,
}

function FormInput({
  type = 'text',
  id,
  label = '',
  fieldState = null,
  value = '',
  name = '',
  onChange = undefined,
  updateForm = undefined,
  cssClass = sharedStyle.FormControlGroup,
  labelCssClass = sharedStyle.FormLabel,
  inputCssClass = style.FormInput,
  validationType,
  showHideCondition,
  extraData = {
    defaultValue: '',
    height: '',
    label: '',
    labelAlign: 'auto',
    maxlength: '',
    placeholder: '',
    readonly: '',
    showLabel: 't',
    validationErrorMessage: '',
    validationRegex: '',
    width: '',
    value: '',
  },
  specialSettings = {
    increments: '1',
    maxLength: '',
    maximumNumber: '',
    minimumNumber: '',
    placeholder: '',
  },
  properties = {},
  isDisabled = false,
  hoverText = '',
  ...props
}: Props & Partial<any>, ref: React.Ref<unknown>) {
  const val = value || fieldState?.fields?.[type] || '';
  const [error, setError] = useState('');
  const inputRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    if ((!!extraData?.defaultValue || !!extraData?.value) && !val) {
      updateForm && updateForm(
        {
          fields: { [type]: extraData.defaultValue || extraData.value },
          calculations: extraData.defaultValue || extraData.value,
        }
      );
    }
  }, [extraData]);

  const selfValidate = () => {
    if (props.required && !val) {
      setError('This field is required.');
      return false;
    }

    if (validationType === 'custom' && !!val && !!(extraData.validationRegex || extraData.validationregex)) { // Handle different case for regex
      const regex = extraData.validationRegex || extraData.validationregex;
      if (!validate(validationType, val, regex)) {
        setError(extraData.validationErrorMessage || `Value doesn't match ${regex}`);
        return false;
      }
    }
    if (!!validationMessages[validationType] && !!val && !validate(validationType, val)) {
      setError(validationMessages[validationType]);
      return false;
    }

    setError('');
    return true;
  };

  useImperativeHandle(
    ref,
    () => ({
      focus: () => inputRef.current?.focus(),
      validate: selfValidate,
    }));

  const toDollarFormat = (num:string) => '$' + num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');

  const handleChange = event => {
    event.preventDefault();
    const { target: { value: v } } = event;
    onChange && onChange(event);
    updateForm && updateForm(
      {
        fields: { [type]: validationType === 'dollar'
          ? toDollarFormat(v.replace(/,|\$/g, ''))
          : v },
        calculations: validationType === 'dollar' ? parseFloat(v.replace(/,|\$/g, '')) : v,
      }
    );
  };

  const { height } = extraData;
  const maxlength: string = extraData?.maxlength || specialSettings?.maxLength;
  const placeholder: string = extraData?.placeholder || specialSettings?.placeholder;
  const CustomCompStyle = buildFormCompStyle(id, properties);
  const maxLengthValue = Number.parseInt(maxlength, 10);
  const isMaxLengthValid = !Number.isNaN(maxLengthValue);

  return (
    <Tooltip
      title={hoverText}
      placement='top'
      disabled={!hoverText}>
      <div className={classNames(cssClass, 'single_input_container')} id={`form_input_${id}`}>
        <CustomCompStyle />
        {showLabelToBoolean(extraData?.showLabel) ?
          <label
            aria-label={label}
            htmlFor={id}
            className={classNames(labelCssClass, { [sharedStyle.Required]: props.required }, 'field_label', getAlignmentClass(extraData.labelAlign, sharedStyle))}>
            {label}
          </label>
          : null
        }
        <ErrorContainer error={error}>
          <input
            ref={inputRef}
            id={id}
            className={classNames(inputCssClass, 'field_input', `${id}-font-settings`)}
            type={type}
            value={val}
            name={name || id}
            onChange={handleChange}
            onBlur={selfValidate}
            placeholder={placeholder === null ? (defaultPlaceholders[type] || defaultPlaceholders.text) : placeholder}
            maxLength={isMaxLengthValid ? maxLengthValue : undefined}
            readOnly={extraData.readonly === 't'}
            disabled={isDisabled}
            style={height ? { height: `${height}px`} : {}}
            max={getMaxValue(specialSettings?.maximumNumber || '', maxlength)}
            min={specialSettings?.minimumNumber || ''}
            step={specialSettings?.increments || ''}
            required={props.required}
            aria-label={label}
            aria-required={props.required}
            aria-invalid={!!error}
            {...props}
          />
        </ErrorContainer>
      </div>
    </Tooltip>
  );
}

export default React.memo(forwardRef(FormInput));
