declare global {
  interface Window {
    SEAMLESSDOCS_AUTH0_CLIENT_ID: string,
    SEAMLESSDOCS_AUTH0_DOMAIN: string,
    SEAMLESSDOCS_AUTH0_DB_CONNECTION: string,
    SEAMLESSDOCS_TRAMPOLINE_SUBDOMAIN: string,
    SEAMLESSDOCS_SERVER_URL: string,
    SEAMLESSDOCS_INSTRUMENTATION_SERVER_URL: string,
    SEAMLESSDOCS_INSTRUMENTATION_SERVER_PATH: string,
    SEAMLESSDOCS_FILEPICKER_KEY: string,
    SEAMLESSDOCS_INTERCOM_KEY: string,
    SEAMLESSDOCS_DISCOVER_USERS_ALLOWED_EDIT: string,
  }
}

export const AUTH0_CLIENT_ID = window.SEAMLESSDOCS_AUTH0_CLIENT_ID || 'SZSjFtY6PpQViQFbbflgGFlIdS1HMwXs';
export const INTERNAL_AUTH0_CLIENT_ID = window.SEAMLESSDOCS_AUTH0_CLIENT_ID || '4zbACCgGJc06CKGPS3fGR8PiTuJXa1eA';

export const AUTH0_DOMAIN = window.SEAMLESSDOCS_AUTH0_DOMAIN || 'seamlessdocsstage.auth0.com';
export const INTERNAL_AUTH0_DOMAIN = window.SEAMLESSDOCS_AUTH0_DOMAIN || 'seamlessdocsinternal.auth0.com';

export const AUTH0_CALLBACK_URL = location.href;
export const AUTH0_DB_CONNECTION = window.SEAMLESSDOCS_AUTH0_DB_CONNECTION || 'SD-Stage';
// window.SEAMLESSDOCS_TRAMPOLINE_SUBDOMAIN can be empty string.
export const AUTH0_ENVIRONMENT = typeof window.SEAMLESSDOCS_TRAMPOLINE_SUBDOMAIN === 'string' ? window.SEAMLESSDOCS_TRAMPOLINE_SUBDOMAIN : 'stage';

export const AUTH0_SCOPE = 'openid identities auid maid subd user_id email name';

export const SERVER_URL = window.SEAMLESSDOCS_SERVER_URL || process.env.SEAMLESSDOCS_SERVER_URL || 'https://backend.stage.seamlessdocs.com/';
export const INSTRUMENTATION_SERVER_URL = window.SEAMLESSDOCS_INSTRUMENTATION_SERVER_URL || process.env.SEAMLESSDOCS_INSTRUMENTATION_SERVER_URL || 'https://backend.stage.seamlessdocs.com/';
export const INSTRUMENTATION_SERVER_PATH = window.SEAMLESSDOCS_INSTRUMENTATION_SERVER_PATH || '/instrumentation';

export const CALL_API = 'CALL_API_ACTION_KEY';

export const FILEPICKER_KEY = window.SEAMLESSDOCS_FILEPICKER_KEY || 'AYSmIDBf2TiKN5ulOAkQrz';

// Don't forget to update `./partials/intercom.html` if you update the `cfy7ppqw` key
export const INTERCOM_APP_ID = window.SEAMLESSDOCS_INTERCOM_KEY || 'cfy7ppqw';

export const INTERNAL_DISCOVER_USERS_ALLOWED_EDIT = window.SEAMLESSDOCS_DISCOVER_USERS_ALLOWED_EDIT || '';

export const TOOLTIP_IO_API_KEY = 'UJ9lBPqnNewgNKkBvNmlwovXj5Rf5H6bxNpZpSNqDK-';
export const TOOLTIP_LOBBY_START_CAMPAIGN_ID = 'Z3BPYqtNGB5P5B4Hs';

export const RECAPTCHA_SITE_KEY = '6LdH5P4SAAAAAOZN2cR2bLtClStFtq77Ixh7Nh_b';

export const GOOGLE_SHEETS_API_KEY = 'AIzaSyDsXV20bdtYBEFKRhABTPs9jEV3q7oGFZM';

export const GOOGLE_MAPS_API_KEY = 'AIzaSyABmBnY_aDH_CutNMf3FATvOvRpcvQmWvU';
