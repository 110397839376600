/**
 * NOTE: Styles for fixed-data-table (Table library used in this component)
 * are in styles/fixed-data-table.css.
 * That style sheet is shared with this component and FormsTable
 */

import React from 'react';
import { connect } from 'react-redux';
import { Cell } from 'fixed-data-table-2';
import _ from 'lodash';
import * as dateHelper from 'utils/dateHelper';
import { isPaymentKey, mapPaymentNodeToObject } from 'utils/submissionsManager/payment';
import SignatureStatusCell from './SignatureStatusCell';
import AssignmentsCell from './AssignmentsCell';
import CompletionStatusCell from './CompletionStatusCell';
import EncryptedCell from './EncryptedCell';
import TagsCell from './TagsCell';
import StagesCell from './StagesCell';
import PaymentCell from './PaymentCell';
import { stripBrTags, cleanUpCellValue } from 'utils/textHelper';
import { attachmentIndexFromKey, isAttachmentKey } from 'utils/submissionsManager/attachments';

import { submissionManagerPermissions } from 'constants/submissionManager';
import {
  ASSIGNMENTS_COLUMN_LABEL,
  COMPLETION_COLUMN_LABEL,
  TAGS_COLUMN_LABEL,
  STAGES_COLUMN_LABEL,
  PAYMENT_AMOUNT_COLUMN_LABEL,
  PAYMENT_STATUS_COLUMN_LABEL,
} from 'constants/tableConsts';


const isReadOnly = (record, capability) =>
  record.isIncomplete || !!(record.permissions && !record.permissions.includes(capability));

class RecordCell extends React.Component<any, any> {
  shouldComponentUpdate(nextProps) {
    const { columnKey, records, rowIndex } = nextProps;
    const props = this.props;
    const shouldRerenderCompletionStatusOnHoverChange =
      columnKey === COMPLETION_COLUMN_LABEL && props.isHovered !== nextProps.isHovered;
    if (shouldRerenderCompletionStatusOnHoverChange) {
      return true;
    }
    if (props.isTableSortable) {
      return true;
    } else if (!_.isEqual(props.records[props.rowIndex], records[rowIndex])) {
      return true;
    } else if (columnKey !== props.columnKey) {
      return true;
    }
    return false;
  }

  handleDecrypt = event => {
    event.stopPropagation();
    const { columnKey, rowIndex, records, handleDecrypt } = this.props;
    const node = records[rowIndex].nodes[columnKey];
    handleDecrypt(node.raw_value, rowIndex, node.input_name);
  };

  render() {
    const { columnKey, isHovered, rowIndex, records, isArchiveView } = this.props;

    if (records.length === 0) {
      return <Cell />;
    }

    let cellValue: string | JSX.Element = '';
    const record = records[rowIndex];
    const node = record && record.nodes ? record.nodes[columnKey] : null;

    if (isPaymentKey(columnKey)) {
      if (record.submissionData.some(field => field.input_name === columnKey)) {
        const payment = mapPaymentNodeToObject(record.payment);
        if (payment) {
          cellValue = `$${payment.amount.raw_value}`;
        }
      }
    } else if (isAttachmentKey(columnKey)) {
      const index = attachmentIndexFromKey(columnKey);
      if (index != null && record.attachments.length > index) {
        cellValue = record.attachments[index].label;
      }
    } else {
      switch (columnKey) {
        case 'createdTs':
          cellValue = dateHelper.getFormattedTime(record.createdTs, dateHelper.TIME_FORMAT_LONG);
          break;
        case 'signatureStatus':
          cellValue = (
            <SignatureStatusCell
              signatureStatus={record.signatureHistory.status || ''}
              totalCompletedSigners={record.signatureHistory.totalCompletedSigners}
              totalSigners={record.signatureHistory.totalSigners} />
          );
          break;
        case COMPLETION_COLUMN_LABEL:
          cellValue = (
            <CompletionStatusCell
              isComplete={!record.isIncomplete}
              showButton={isHovered}
              submissionId={record.submissionId} />
          );
          break;
        case ASSIGNMENTS_COLUMN_LABEL:
          cellValue = (
            <AssignmentsCell
              assigneeIds={record.assignees}
              isReadOnly={
                isReadOnly(record, submissionManagerPermissions.canEditAssignees) || isArchiveView || record.isArchived}
              submissionId={record.submissionId} />
          );
          break;
        case TAGS_COLUMN_LABEL:
          cellValue = (
            <TagsCell
              isReadOnly={
                isReadOnly(record, submissionManagerPermissions.canEditTags) || isArchiveView || record.isArchived}
              submissionId={record.submissionId}
              tagNames={record.tags} />
          );
          break;
        case STAGES_COLUMN_LABEL:
          cellValue = (
            <StagesCell
              isReadOnly={
                isReadOnly(record, submissionManagerPermissions.canEditStages) || isArchiveView || record.isArchived}
              submissionId={record.submissionId}
              stageName={record.stageName} />
          );
          break;
        case PAYMENT_AMOUNT_COLUMN_LABEL:
          cellValue = (
            <PaymentCell
              isReadOnly={isReadOnly(record, submissionManagerPermissions.canExecuteTransactions)}
              submissionId={record.submissionId}
              payment={record.payments || record.govOsPayments} />
          );
          break;
        case PAYMENT_STATUS_COLUMN_LABEL:
          cellValue = (
            <PaymentCell
              isReadOnly={isReadOnly(record, submissionManagerPermissions.canExecuteTransactions)}
              submissionId={record.submissionId}
              payment={record.payments || record.govOsPayments}
              isStatus />
          );
          break;
        default:
          cellValue = (node && node.raw_value) || '';
      }
    }

    if (node && node.isEncrypted && node.raw_value) {
      cellValue = <EncryptedCell onClick={this.handleDecrypt} />;
    }

    return (<Cell>
      {stripBrTags(cleanUpCellValue(cellValue as string, node ? node.field_map : null, record) as string)}
    </Cell>);
  }
}

const mapStateToProps = state => {
  const { submissionTable: { isArchiveView } } = state;
  return {
    isArchiveView,
  };
};

export default connect(
  mapStateToProps,
)(RecordCell);
