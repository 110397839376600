/* eslint-disable no-console */
import React, {
  useState,
  forwardRef,
  MouseEvent,
  lazy,
  Suspense,
  useCallback,
  useImperativeHandle,
  useEffect,
  MouseEventHandler,
  memo,
} from 'react';
import { connect } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';
import classNames from 'classnames';
import idVerificationStyles from './IdVerification.css';
import sharedStyle from 'form-comps/shared.css';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import signatureIcon from 'icons/signature.svg';
import arrowBack from 'icons/arrowBack.svg';
import { ControlState } from 'types/liveView';
import { SERVER_URL } from '../../env';
import Loader from './Loader';
import VerifiedData from './VerifiedData';

const PersonaReact = lazy(() => import('persona-react'));

const ID_VERIFICATION_NAME = 'Identity Verification';
const ID_VERIFICATION_DESCRIPTION = 'Upload a government ID to verify your identity.';
const VERIFY_BTN_TEXT = 'Verify Identity';
const BACK_TO_FORM_TXT = 'Back to form';
const CONFIG_ERROR_TEXT = 'Error: This element has not been properly configured.';

type PropsFromState = {
  formId: string;
  maid: string;
};

export type IDVerificationProps =
  PropsFromState & {
  fieldState: ControlState | undefined;
  id: string;
  label: string;
  elementType: string;
  required: boolean;
  extraData: {
    label: string;
    showLabel: string;
    labelAlign: string;
    width: string;
  }
  excludeFromPreview: boolean;
  isDisabled: boolean;
  specialSettings: {
    labelAlignment: string;
    width: number;
    disabled: boolean;
    id: string;
    label: string;
    templateId: string;
    showLabel: boolean;
  }
  updateForm?: (state: ControlState) => void;
}

type ConfigurationErrorProps = {
  label: string;
  isRequired: boolean;
};

type ModalContentProps = {
  onComplete: () => void;
  closeModalHandler: MouseEventHandler<HTMLButtonElement>;
  templateId: string;
  referenceId: string;
}

export const ConfigurationError = ({
  label,
  isRequired,
}: ConfigurationErrorProps) => (
  <div className={idVerificationStyles.textWrapper}>
    <label className={classNames(idVerificationStyles.title, { [sharedStyle.Required]: isRequired })}>
      {label || ID_VERIFICATION_NAME}
    </label>
    <p className={classNames(idVerificationStyles.subTitle, idVerificationStyles.errorText)}>{CONFIG_ERROR_TEXT}</p>
  </div>
);

const ModalContent = memo(({
  closeModalHandler,
  templateId,
  referenceId,
  onComplete,
}: ModalContentProps) => (
  <div className={idVerificationStyles.personaContainer}>
    <button
      aria-label='Close modal and go back to form'
      onClick={closeModalHandler}
      className={idVerificationStyles.backToFormButton}
    >
      <img src={arrowBack} alt='Go back to form' />
      {BACK_TO_FORM_TXT}
    </button>
    <div className={idVerificationStyles.personaWrapper}>
      <Suspense fallback={<Loader />}>
        <PersonaReact
          templateId={templateId}
          // TODO: Get the actual environment ID
          environmentId='env_DvhP4Crdz3UqGjNB6QqCpLRf'
          onComplete={onComplete}
          referenceId={referenceId}
        />
      </Suspense>
    </div>
  </div>
));

const createRecord = async(refId: string, formId: string, maid: string) => {
  const url = `${SERVER_URL}IdVerification/startVerification.json`;
  const body = JSON.stringify({ id: refId, formId, maid });
  const headers = { 'Content-Type': 'application/json' };
  try {
    const response = await fetch(url, { method: 'POST', body, headers });
    if (response.ok) {
      return response.json();
    }
    throw new Error(`Failed to create record. Status: ${response.status}`);
  } catch (fetchError) {
    console.error('Error creating record:', fetchError);
  }
  return null;
};

const fetchVerifiedData = async(refId: string) => {
  const url = `${SERVER_URL}IdVerification/get.json`;
  const body = JSON.stringify({ idVerification: refId });
  const headers = { 'Content-Type': 'application/json' };
  try {
    const response = await fetch(url, { method: 'POST', body, headers });
    if (response.ok) {
      return response.json();
    }
    throw new Error(`Failed to fetch record. Status: ${response.status}`);
  } catch (fetchError) {
    console.error('Error fetching record:', fetchError);
  }
  return null;
};

export const IdVerification = forwardRef((props: IDVerificationProps, ref) => {
  const {
    fieldState,
    updateForm,
    formId,
    maid,
    specialSettings,
  } = props;
  const { templateId } = specialSettings;

  const status = fieldState?.extraData?.status || null;
  const referenceId = fieldState?.fields?.referenceId || '';
  const hasCreatedRecord = fieldState?.extraData?.hasCreatedRecord || false;
  const verifiedData = fieldState?.extraData?.verifiedData || null;

  const [showPersonaComponent, setShowPersonaComponent] = useState<boolean>(false);
  const [showLoader, setShowLoader] = useState<boolean>(false);
  const [error, setError] = useState<string>('');

  useEffect(() => {
    if (!referenceId) {
      const state = { fields: { referenceId: uuidv4() } };
      updateForm && updateForm(state);
    }
  }, [referenceId, updateForm]);

  const selfValidate = () => {
    if (props.required && !status) {
      setError('This field is required.');
      return false;
    }
    setError('');
    return true;
  };

  useImperativeHandle(ref, () => ({ validate: selfValidate }));

  const fetchData = (retriesLeft: number) => {
    if (retriesLeft < 1) {
      setError('Failed to fetch record. Please try again.');
      setShowLoader(false);
      return;
    }

    fetchVerifiedData(referenceId)
      .then(data => {
        const inquiryStatus = data?.result?.status;
        if (inquiryStatus !== 'COMPLETED') {
          // Retry fetching the data after 2 seconds
          setTimeout(() => fetchData(retriesLeft - 1), 2000);
          return;
        }

        const fetchedVerifiedData = data?.result?.formattedWebhookPayload;
        const state: ControlState = {
          fields: { ...fieldState?.fields },
          extraData: {
            ...fieldState?.extraData,
            verifiedData: fetchedVerifiedData,
            status: inquiryStatus,
          },
        };
        updateForm && updateForm(state);
        setShowLoader(false);
        setShowPersonaComponent(false);
      })
      .catch(() => {
        setShowLoader(false);
        setError('Failed to fetch record. Please try again.');
      });
  };

  const onComplete = (): void => {
    setShowPersonaComponent(false);
    setShowLoader(true);
    updateForm && updateForm({
      fields: { ...fieldState?.fields },
      extraData: { ...fieldState?.extraData, isFetching: true },
    });
    fetchData(3); // Try fetching the data up to 3 times
  };

  const openModalHandler = useCallback((e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowPersonaComponent(true);
    if (!hasCreatedRecord) {
      createRecord(referenceId, formId, maid)
        .then(() => {
          const state: ControlState = {
            fields: { ...fieldState?.fields },
            extraData: { ...fieldState?.extraData, hasCreatedRecord: true },
          };
          updateForm && updateForm(state);
        })
        .catch(() => {
          setError('Failed to create record. Please try again.');
        });
    }
  }, [hasCreatedRecord, referenceId, formId, maid, fieldState, updateForm]);

  const closeModalHandler = useCallback((e: MouseEvent<HTMLButtonElement>): void => {
    e.preventDefault();
    setShowPersonaComponent(false);
    setShowLoader(false); // Reset the loading state
  }, []);

  if (!templateId) {
    return <ConfigurationError label={props.label} isRequired={props.required} />;
  }

  if (verifiedData) {
    return <VerifiedData data={verifiedData} />;
  }

  return (
    <div id={props.id} className={idVerificationStyles.container}>
      <ErrorContainer error={error}>
        <div className={idVerificationStyles.textWrapper}>
          <label className={classNames(idVerificationStyles.title, { [sharedStyle.Required]: props.required })}>
            {props.label || ID_VERIFICATION_NAME}
          </label>
          <p className={idVerificationStyles.subTitle}>{ID_VERIFICATION_DESCRIPTION}</p>
        </div>
      </ErrorContainer>
      <div className={idVerificationStyles.buttonWrapper}>
        <button
          className={idVerificationStyles.verifyButton}
          onClick={openModalHandler}
          disabled={props.isDisabled}
          aria-disabled={props.isDisabled}
        >
          <img src={signatureIcon} alt='Signature icon' />
          <span>{VERIFY_BTN_TEXT}</span>
        </button>
      </div>
      {/* eslint-disable-next-line no-nested-ternary */}
      {showPersonaComponent ? (
        <div className={idVerificationStyles.overlay} role='dialog' aria-modal='true'>
          <ModalContent
            closeModalHandler={closeModalHandler}
            templateId={templateId}
            referenceId={referenceId}
            onComplete={onComplete}
          />
        </div>
      ) : showLoader ? (
        <Loader />
      ) : null}
    </div>
  );
});

const mapStateToProps = (state: any): PropsFromState => ({
  formId: state?.forms?.liveView?.form?.form?.formId,
  maid: state?.auth?.maid,
});

export default connect(mapStateToProps, null, null, { forwardRef: true })(IdVerification);
