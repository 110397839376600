import React from 'react';
import {bindActionCreators} from 'redux';
import {connect} from 'react-redux';
import {replace} from 'react-router-redux';
import {debounce, isEmpty} from 'lodash';
import Helmet from 'react-helmet';
import Snackbar from '@material-ui/core/Snackbar';
import LobbyDragLayer from './LobbyDragLayer';
import injectPermissions from 'decorators/injectPermissions';
import {withNavBarContext} from 'components/OneNavBar';
import dragDropContext from 'containers/dragDropContext';
import PermissionsModal from 'containers/Permissions';
import Loader from 'components/Loader';
import QuickFilters from 'containers/QuickFilters';
import NotificationLoaderModal from 'components/Modals/NotificationLoaderModal';
import ErrorNotification from 'components/ErrorNotification';
import CreateFolderModal from 'components/Modals/CreateFolderModal';
import FolderTree from 'components/FolderTree';
import FormsList from './FormsList';
import {folderItemType, formItemType} from 'components/FolderTree/Node';
import {WindowSize, getWindowSizeData} from 'components/WindowSize';
import styles from 'styles/shared/containerStyles.css';
import {parse, parsedToString, parsedIsEquivalent} from 'utils/search';
import * as routing from 'utils/routing';
import * as lobbyActions from 'actions/lobby/lobbyActions';
import * as lobbyTableApi from 'actions/lobby/tableApiActions';
import * as folderNavigationActions from 'actions/lobby/folderNavigationActions';
import {selectQuickFilter} from 'actions/quickFilters';
import {initializePermissions, toggleModal} from 'actions/permissions';
import {logSubmissionNavigation} from 'actions/routing';
import {
  getSelectedQuickFilter,
  getSubmissionManagerRoute,
  getPath,
  getUserCapabilitiesLoadingState,
  getUserRole,
} from '../../reducers';
import * as applicationCopy from 'constants/applicationCopy';
import {LOBBY_ARCHIVED_FOLDER, LOBBY_ROOT_FOLDER, formCapabilitiesEnum} from 'constants/tableConsts';
import * as Colors from 'utils/colors';
import {isArchiveFolder} from 'utils/lobby/folders';
import {isVisitor} from 'utils/auth';
import {quickFiltersEnum} from 'constants/quickFilters';
import {COMPANY_NAME} from 'constants/applicationCopy';
import breadcrumbs from './breadcrumbs';
import LobbyToolbar from './LobbyToolbar';
import {SplitTreatments} from '@splitsoftware/splitio-react';
import {FROM_TOOLBAR} from 'types/routing';
import {selectTreatmentValue} from '@splitsoftware/splitio-redux';
import { navBarSearchInputChange } from 'actions/navBarSearch';


class Lobby extends React.Component<any, any> {
  constructor(props) {
    super(props);

    const {
      isMobile,
      isPhone,
      isTablet,
      width: windowWidth,
      height: wrapperHeight,
    } = getWindowSizeData();
    this.state = {
      isMobile,
      isPhone,
      isTablet,
      mounted: true,
      shouldShowFolderTreeIfMobile: false,
      windowWidth,
      wrapperHeight,
    };
  }

  componentDidMount() {
    const { root } = this.props.lobbyNavigation;
    if (!root || isEmpty(root.data)) {
      this.props.folderNavigationActions.getDirJSON();
    }
    if (
      this.props.navBarContext &&
      this.props.navBarContext.setSearchPlaceHolder
    ) {
      this.props.navBarContext.setSearchPlaceHolder('Search Forms Manager');
    }
    this.clearSearchBar();
  }

  componentDidUpdate(prevProps) {
    const {
      userCapabilitiesLoaded,
      canUserAccessFolderTree,
      selectedQuickFilter,
      lobby: { selectedRowData },
      navBarSearch: { searchBarInput },
      lobbyNavigation,
      isSearchEnabled,
      navBarContext,
      tableApi,
    } = this.props;
    const hasSearchInput = searchBarInput && searchBarInput.length > 0;
    const hasValue = hasSearchInput && searchBarInput[0].value !== '';
    const hasValueLength = hasValue && searchBarInput[0].value.length > 3;
    // Check for user capabilities
    if (userCapabilitiesLoaded) {
      if (!canUserAccessFolderTree() && !selectedQuickFilter) {
        this.props.selectQuickFilter(quickFiltersEnum.ALL, false);
      }
      if (prevProps.lobby.selectedRowData !== selectedRowData || prevProps.lobbyNavigation !== lobbyNavigation) {
        if (hasValueLength) {
          this.clearSearchBar();
        }
        this.updateToolbarIcons();
      }
    }

    // Set search callbacks if search is enabled and not already set
    if (isSearchEnabled && !navBarContext.values.search.onChange) {
      navBarContext.setSearchCallbacks({
        onChange: (value: string) =>
          this.handleSearchbarInputChange(parse(value)),
      });
    }

    // Handle search-related operations
    if (isSearchEnabled && hasSearchInput && prevProps.navBarSearch.searchBarInput &&
      !parsedIsEquivalent(searchBarInput, prevProps.navBarSearch.searchBarInput)) {
      this.handleSearch(searchBarInput, prevProps.navBarSearch.searchBarInput, false);

      if (lobbyNavigation.currentPath.filter((pathStr: string): boolean => pathStr !== '').length > 0) {
        this.props.folderNavigationActions.selectFolder([''], lobbyNavigation.root);
      }

      if (selectedQuickFilter !== null) {
        this.props.selectQuickFilter(quickFiltersEnum.ALL, false);
        tableApi.newTable(LOBBY_ROOT_FOLDER);
      }
    }
  }
  clearSearchBar = (): void => {
    const {
      navBarSearch: { searchBarInput },
    } = this.props;

    const hasValue =
      searchBarInput?.length > 0 && searchBarInput[0].value !== '';

    if (hasValue) {
      this.props.navBarSearchInputChange([]);
    }
  };

  updateToolbarIcons(): void {
    const {
      accountHasGRM,
      navBarContext: { setButtons },
      submissionManagerRoute,
      lobbyNavigation,
      canUserAccessGRM,
      lobby: { selectedRowData },
      isNgLiveViewEnabled,
    } = this.props;

    if (!selectedRowData) {
      setButtons([]);
      return;
    }

    const currentFolderId = lobbyNavigation.currentNode.meta.folderId;
    if (isArchiveFolder(currentFolderId)) {
      setButtons([
        [
          {
            label: 'Restore',
            icon: 'restore',
            onClick: this.handleRestoreArchived,
          },
          {
            label: 'Delete',
            icon: 'trash',
            onClick: this.handleOpenDeleteFormConfirmationModal,
          },
        ],
      ]);
      return;
    }

    const buttons: any[] = [];

    const viewFormUrl =
      selectedRowData &&
      (isNgLiveViewEnabled
        ? routing.formatLiveviewUrl(selectedRowData)
        : routing.formatLegacyLiveviewUrl(selectedRowData));
    const editFormUrl =
      selectedRowData && routing.formatLegacyEditFormUrl(selectedRowData);
    const capabilities = selectedRowData.capabilities || [];

    buttons.push({ label: 'View Form', icon: 'view', url: viewFormUrl });

    if (capabilities.includes(formCapabilitiesEnum.Edit)) {
      buttons.push({ label: 'Edit Form', icon: 'edit', url: editFormUrl });
    }

    if (capabilities.includes(formCapabilitiesEnum.EditPermissions)) {
      buttons.push({
        label: 'Permissions',
        icon: 'permissions',
        onClick: this.handleOpenPermissionsModal,
      });
    }

    if (capabilities.includes(formCapabilitiesEnum.Archive)) {
      buttons.push({
        label: 'Archive',
        icon: 'trash',
        onClick: this.handleOpenArchiveConfirmationModal,
      });
    }

    buttons.push({
      label: 'Submissions',
      icon: 'submissions',
      url: `${submissionManagerRoute}/${selectedRowData.formId}`,
      onClick: () =>
        this.props.logSubmissionNavigation(
          FROM_TOOLBAR,
          selectedRowData.numSubmissions,
        ),
    });

    // According to issue ENG-1486 conversation, if no submissions are viewable,
    // GRM button is pointless hence the check below.

    if (
      accountHasGRM &&
      canUserAccessGRM() &&
      (selectedRowData.numSubmissions || selectedRowData.numSubmissionsDeleted)
    ) {
      buttons.push({
        label: 'GRM',
        icon: 'grm',
        url: routing.grmPipeline(selectedRowData.formId),
      });
    }

    setButtons([buttons]);
  }

  _selectFolder = (node, path): void => {
    const folderId = node.meta.folderId;
    this.props.folderNavigationActions.selectFolder(path, node);
    if (node.meta.folderId === LOBBY_ARCHIVED_FOLDER) {
      this.props.tableApi.loadArchivedTable();
    } else {
      folderId && this.props.tableApi.newTable(folderId);
    }
  };

  _selectParentFolder = (): void => {
    const { lobbyNavigation } = this.props;
    const path = lobbyNavigation.currentPath;
    const pathLength = path.length;
    const parent = lobbyNavigation.currentNode.parent;

    if (parent) {
      this.props.folderNavigationActions.getDirJSON(parent);
      this._selectFolder(parent, path.slice(0, pathLength - 1));
      this.setState({ shouldShowFolderTreeIfMobile: false });
    }
  };

  renderLeftSidebar = (sidebarWidth: number) => {
    const { lobbyNavigation, isQuickFiltersEnabled } = this.props;
    const sidebarStyles = {
      backgroundColor: Colors.white,
      top: 0,
      bottom: 0,
      order: '1',
      overflowY: 'scroll',
      position: 'absolute',
      width: sidebarWidth,
      borderRight: `1px solid ${Colors.grey250}`,
    };
    return (
      <div style={sidebarStyles as any}>
        {isQuickFiltersEnabled && (
          <QuickFilters
            onSelect={this.handleQuickFilterSelect}
            clearSearchBar={this.clearSearchBar}
          />
        )}
        <FolderTree
          archive={lobbyNavigation.archive}
          currentNode={lobbyNavigation.currentNode}
          currentPath={lobbyNavigation.currentPath}
          onArchive={this.handleFolderTreeArchive}
          onClick={this.handleFolderTreeOnClick}
          onCollapse={this.onNodeCollapse}
          onCreateFolder={this.onCreateFolder}
          onDeleteFolder={this.handleOpenDeleteFolderModal}
          onDrop={this.onFolderDrop}
          onExpand={this.onNodeExpand}
          onNameChange={this.onFolderNameChange}
          root={lobbyNavigation.root}
        />
      </div>
    );
  };

  renderModals = () => {
    const { lobby } = this.props;
    return (
      <React.Fragment>
        <NotificationLoaderModal
          title={lobby.loaderTitle}
          status={lobby.loaderStatus}
          open={lobby.loaderVisibile}
          bodyText={lobby.loaderBody}
          handleNotificationModalClose={this.handleNotificationModalClose}
          handleArchiveForm={this.handleArchiveForm}
          handleDeleteFolder={this.handleDeleteFolder}
          handleDeleteForm={this.handleDeleteForm}
        />
        <CreateFolderModal
          open={lobby.createFolderOpen}
          onClose={this.handleCreateFolderModalClose}
          onCreate={this.handleCreateFolder}
        />
        <PermissionsModal />
      </React.Fragment>
    );
  };

  onNodeExpand = node => {
    if (
      node.meta.folderId !== LOBBY_ROOT_FOLDER &&
      node.meta.subfolderCount !== Object.keys(node.data).length
    ) {
      this.props.folderNavigationActions.getDirJSON(node);
    } else {
      this.props.folderNavigationActions.expandDir(node);
    }
  };

  onNodeCollapse = node => {
    this.props.folderNavigationActions.collapseDir(node);
  };

  onFolderDrop = (source, target): void => {
    switch (source.itemType) {
      case formItemType:
        target.tree.meta.folderId &&
          this.props.folderNavigationActions.moveFormIntoFolder(
            source.index,
            source.rowData.formId,
            target.tree.meta.folderId,
          );
        return;
      case folderItemType:
        this.props.folderNavigationActions.moveDirectory(
          source.tree,
          target.tree,
        );
        return;
      default:
        return;
    }
  };

  onFolderNameChange = (node, newName): void => {
    this.props.folderNavigationActions.renameDirectory(node, newName);
  };

  onCreateFolder = (): void => {
    this.props.lobbyActions.createFolderModalVisible(true);
  };

  handleSearchbarInputChange = (
    input: { start: number; end: number; type: string; value: string }[],
  ): void => {
    this.props.navBarSearchInputChange(input);
  };

  // debounce search to avoid extra calls
  handleSearch = debounce((input, previousInput, force = false): void => {
    const inputString = parsedToString(input);
    if (force || !parsedIsEquivalent(input, previousInput)) {
      if (inputString) {
        this.props.tableApi.searchFormsInitial(inputString);
      } else {
        this.props.tableApi.newTable(LOBBY_ROOT_FOLDER);
      }
    }
  }, 500);

  handleFolderTreeOnClick = (node, path, isDragging): void => {
    this.clearSearchBar();
    this._selectFolder(node, path);
    if (!isDragging) {
      this.setState({ shouldShowFolderTreeIfMobile: false });
    }
  };

  handleQuickFilterSelect = (): void => {
    this.setState({ shouldShowFolderTreeIfMobile: false });
  };

  handleWindowSizeOnChange = ({ isMobile, isPhone, isTablet, width }): void => {
    const { wrapper } = this.refs;
    const wrapperHeight = wrapper
      ? (wrapper as any).offsetHeight
      : this.state.wrapperHeight;
    this.setState({
      isMobile,
      isPhone,
      isTablet,
      mounted: !!wrapperHeight,
      windowWidth: width,
      wrapperHeight,
    });
  };

  handleLobbyToolbarBackIconOnClick = (
    event: React.MouseEvent<HTMLButtonElement>,
  ): void => {
    event.preventDefault();
    this.setState({ shouldShowFolderTreeIfMobile: true });
  };

  handleBackArrowOnClick = (): void => {
    const {
      lobbyNavigation: { currentNode, root },
      selectedQuickFilter,
    } = this.props;
    const isArchive = currentNode.meta.folderId === LOBBY_ARCHIVED_FOLDER;
    const isInQuickFilter = !!selectedQuickFilter;
    const parentNode = isArchive || isInQuickFilter ? root : currentNode.parent;
    parentNode && this._selectFolder(parentNode, parentNode.path());
    this.setState({ shouldShowFolderTreeIfMobile: false });
  };

  handleArchiveForm = (): void => {
    const { tableApi, lobby } = this.props;
    const { selectedRowData, selectedRowIndex } = lobby;

    if (selectedRowData && typeof selectedRowIndex === 'number') {
      tableApi.archiveFormRequest(selectedRowData, selectedRowIndex);
    }
  };

  handleOpenArchiveConfirmationModal = (): void => {
    this.props.lobbyActions.loaderModalToggle(
      true,
      'archive',
      applicationCopy.ARCHIVE_FORM_TITLE,
      applicationCopy.ARCHIVE_FORM_BODY,
    );
  };

  handleOpenDeleteFormConfirmationModal = (): void => {
    this.props.lobbyActions.loaderModalToggle(
      true,
      'deleteForm',
      'Delete Form',
      'Are you sure you want to delete this form?',
    );
  };

  handleNotificationModalClose = (): void => {
    this.props.lobbyActions.loaderModalToggle(false, '', '', '');
  };

  handleCreateFolder = (name: string): void => {
    const { currentNode, root } = this.props.lobbyNavigation;
    const isArchive = currentNode.meta.folderId === LOBBY_ARCHIVED_FOLDER;
    const parentNode = isArchive ? root : currentNode;

    this.props.folderNavigationActions.createDirectory(name, parentNode);
    this.props.lobbyActions.createFolderModalVisible(false);
  };

  handleCreateFolderModalClose = (): void => {
    this.props.lobbyActions.createFolderModalVisible(false);
  };

  handleResetSnackbar = (): void => {
    this.props.tableApi.resetTableSnackbar();
  };

  handleDeleteFolder = (): void => {
    const { lobbyNavigation } = this.props;
    const folderId = lobbyNavigation.currentNode.meta.folderId;

    if (folderId) {
      this.props.folderNavigationActions.deleteFolder(
        folderId,
        this._selectParentFolder,
      );
    }
  };

  handleOpenDeleteFolderModal = (): void => {
    this.props.lobbyActions.loaderModalToggle(
      true,
      'deleteFolder',
      applicationCopy.DELETE_FOLDER_TITLE,
      applicationCopy.DELETE_FOLDER_BODY,
    );
  };

  handleFolderTreeArchive = source => {
    switch (source.itemType) {
      case formItemType:
        this.handleOpenArchiveConfirmationModal();
        return;
      case folderItemType:
        this.handleOpenDeleteFolderModal();
        return;
      default:
        return;
    }
  };

  handleRestoreArchived = (): void => {
    const { selectedRowData, selectedRowIndex } = this.props.lobby;
    if (selectedRowData && typeof selectedRowIndex === 'number') {
      this.props.tableApi.archiveFormRequest(
        selectedRowData,
        selectedRowIndex,
        true,
      );
    }
  };

  handleDeleteForm = (): void => {
    const { selectedRowData } = this.props.lobby;
    if (selectedRowData) {
      this.props.tableApi.deleteForm(selectedRowData.formId);
    }
  };

  handleOpenPermissionsModal = () => {
    const { selectedRowData } = this.props.lobby;
    if (selectedRowData) {
      const formId = selectedRowData.formId;
      const isPdf = selectedRowData.itemType === 'Doc';
      this.props.permissionsActions.initializePermissions({ formId, isPdf });
      this.props.permissionsActions.toggleModal(
        !this.props.permissions.modalOpen,
      );
    }
  };

  render() {
    const {
      canUserAccessSD,
      isSnackbarOpen,
      snackbarMessage,
      lobbyNavigation,
      location,
      router,
      userCapabilitiesLoaded,

      lobby,
      path,
      submissionManagerRoute,
      maid,
      subdomain,
    } = this.props;

    if (!userCapabilitiesLoaded || !canUserAccessSD()) return <Loader />;

    const {
      isMobile,
      shouldShowFolderTreeIfMobile,
      wrapperHeight,
      windowWidth,
      mounted,

      isPhone,
      isTablet,
    } = this.state;

    const { selectedRowData } = lobby;

    const usableWindowWidth = windowWidth;

    const sidebarWidth = isMobile ? usableWindowWidth : 300;
    const formsListWidth = isMobile
      ? usableWindowWidth
      : usableWindowWidth - sidebarWidth;

    const shouldShowLeftSidebar = shouldShowFolderTreeIfMobile || !isMobile;
    const shouldShowFormsList = !(shouldShowFolderTreeIfMobile && isMobile);
    const currentFolderId = lobbyNavigation.currentNode.meta.folderId;
    const isInArchive = isArchiveFolder(currentFolderId);
    const formsListContainerStyles = {
      left: shouldShowLeftSidebar ? sidebarWidth : 0,
      position: 'absolute',
    };
    const { deployment } = routing;
    return (
      <div
        className={styles.Container}
        style={{ width: `${usableWindowWidth}px` }}
      >
        <LobbyDragLayer />
        <Helmet
          title={`${COMPANY_NAME}: ${lobbyNavigation.currentNode.name}`}
        />
        <WindowSize onChange={this.handleWindowSizeOnChange} />

        <SplitTreatments names={['ONE_NAV']} attributes={{ maid, deployment }}>
          {({ treatments: { ONE_NAV }, isReady }) => {
            if (!isReady || ONE_NAV.treatment === 'on') {
              return null;
            }

            return (
              <LobbyToolbar
                backIconOnClick={this.handleLobbyToolbarBackIconOnClick}
                handleOpenArchiveConfirmationModal={
                  this.handleOpenArchiveConfirmationModal
                }
                handleOpenDeleteConfirmationModal={
                  this.handleOpenDeleteFormConfirmationModal
                }
                handleOpenPermissionsModal={this.handleOpenPermissionsModal}
                isInArchive={isInArchive}
                isMobile={isMobile}
                logSubmissionNavigation={this.props.logSubmissionNavigation}
                onRestoreArchived={this.handleRestoreArchived}
                searchbarInput={lobby.searchbarInput}
                searchbarOpen={lobby.searchbarOpen}
                selectedRowData={selectedRowData}
                submissionManagerRoute={submissionManagerRoute}
                titleContent={breadcrumbs(
                  path,
                  isPhone,
                  isTablet,
                  this.handleBackArrowOnClick,
                )}
                toolbarMobileMenuVisibility={
                  this.props.lobbyActions.toolbarMobileMenuVisibility
                }
                toolbarMobileMenuVisible={lobby.toolbarMobileMenuVisible}
                windowWidth={usableWindowWidth}
                subdomain={subdomain}
              />
            );
          }}
        </SplitTreatments>

        <ErrorNotification
          autoHideDuration={8000}
          router={router}
          location={location}
        />
        <div ref='wrapper' className={styles.Subcontainer}>
          {shouldShowLeftSidebar && this.renderLeftSidebar(sidebarWidth)}
          {shouldShowFormsList && (
            <FormsList
              containerStyles={formsListContainerStyles}
              width={formsListWidth}
              height={wrapperHeight}
              isInArchive={isInArchive}
              mounted={mounted}
              clearSearchBar={this.clearSearchBar}
            />
          )}
          {this.renderModals()}
          <Snackbar
            open={isSnackbarOpen || false}
            message={snackbarMessage || ''}
            autoHideDuration={1500}
            onClose={this.handleResetSnackbar}
          />
        </div>
      </div>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  lobbyActions: bindActionCreators(lobbyActions, dispatch),
  folderNavigationActions: bindActionCreators(
    folderNavigationActions,
    dispatch,
  ),
  permissionsActions: bindActionCreators(
    {
      initializePermissions,
      toggleModal,
    },
    dispatch,
  ),
  tableApi: bindActionCreators(lobbyTableApi, dispatch),
  router: bindActionCreators({ replace }, dispatch),
  ...bindActionCreators(
    {
      navBarSearchInputChange,
      logSubmissionNavigation,
      selectQuickFilter,
    },
    dispatch,
  ),
});

const mapStateToProps = state => {
  const {
    auth,
    features: {
      QUICK_FILTERS_SUPPORT,
      VISITOR_ACCESS_SUPPORT,
      GRM_WITH_SC,
      GRM_WITHOUT_SC,
    },
    lobby,
    lobbyTable,
    folderNavigation,
    permissions: { formId, modalOpen, isPdf },
    navBarSearch,
    splitio,
  } = state;

  /*
  Usually we answer questions like this via server-provided user capabilities, but we are not currently computing
  these values on the server, only providing a static set. Since this is dependent on the feature flag as well as the
  user, we have to compute it client-side.
  */
  const canUserAccessSD = () =>
    VISITOR_ACCESS_SUPPORT || !isVisitor(getUserRole(state));
  const submissionManagerRoute = getSubmissionManagerRoute(state);

  return {
    accountHasGRM: GRM_WITH_SC || GRM_WITHOUT_SC,
    canUserAccessSD,
    lobby,
    navBarSearch,
    isQuickFiltersEnabled: QUICK_FILTERS_SUPPORT,
    isSnackbarOpen: auth.isSnackbarOpen || lobbyTable.isSnackbarOpen,
    path: getPath(state),
    selectedQuickFilter: getSelectedQuickFilter(state),
    snackbarMessage: auth.snackbarMessage || lobbyTable.snackbarMessage,
    submissionManagerRoute,
    lobbyNavigation: folderNavigation,
    permissions: { formId, modalOpen, isPdf },
    userCapabilitiesLoaded: !getUserCapabilitiesLoadingState(state),
    userId: auth.profile ? auth.profile.auid : '',
    maid: state.auth.profile && state.auth.profile.maid,
    subdomain: auth.profile ? auth.profile.subd : '',
    isNgLiveViewEnabled: selectTreatmentValue(splitio, 'NG_VIEW_FORM') === 'on',
    isSearchEnabled: selectTreatmentValue(splitio, 'LOBBY_SEARCH') === 'on',
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(withNavBarContext(injectPermissions(dragDropContext(Lobby))));
