export const INITIALIZE = 'SM_INITIALIZE';

export const ALL_SUBMISSION_FILTER_INITIAL_LOAD = 'SM_ALL_SUBMISSION_FILTER_INITIAL_LOAD';
export const ALL_SUBMISSION_FILTER_SUBSEQUENT_LOAD = 'SM_ALL_SUBMISSION_FILTER_SUBSEQUENT_LOAD';
export const ARCHIVED_SUBMISSION_FILTER_INITIAL_LOAD = 'SM_ARCHIVED_SUBMISSION_FILTER_INITIAL_LOAD';
export const ARCHIVED_SUBMISSION_FILTER_SUBSEQUENT_LOAD = 'SM_ARCHIVED_SUBMISSION_FILTER_SUBSEQUENT_LOAD';
export const NEW_TABLE_SEARCH_API = 'SM_NEW_TABLE_SEARCH_API';

export const UPDATE_TABLE_SEARCH_API = 'SM_UPDATE_TABLE_SEARCH_API';

export const SELECT_TABLE_RECORD = 'SM_SELECT_TABLE_RECORD';
export const SELECT_ALL_RECORDS = 'SM_SELECT_ALL_RECORDS';

export const RECORD_DETAILS = 'SM_RECORD_DETAILS';

export const FORM_DETAILS = 'SM_FORM_DETAILS';

export const SUBMISSIONS_STATS = 'SM_SUBMISSIONS_STATS';

export const MAKE_SORTABLE = 'SM_MAKE_SORTABLE';

export const ARCHIVE_SUBMISSION_API = 'SM_ARCHIVE_SUBMISSION_API';

export const DELETE_ARCHIVED_SUBMISSION_API = 'SM_DELETE_ARCHIVED_SUBMISSION_API';

export const RESET_TABLE_SNACKBAR = 'SM_RESET_TABLE_SNACKBAR';

export const TOGGLE_SNACKBAR = 'SM_TOGGLE_SNACKBAR';

export const USE_CACHED_TABLE = 'SM_USE_CACHED_TABLE';

export const EXPORT_SUBMISSION_API = 'SM_EXPORT_SUBMISSION_API';

export const COLUMN_ORDER_API = 'SM_COLUMN_ORDER_API';

export const DECRYPT_FIELD_API = 'SM_DECRYPT_FIELD_API';

export const RENOTIFY_API = 'SM_RENOTIFY_API';

export const RESET_SELECTED_ROWS = 'SM_RESET_SELECTED_ROWS';

export const GET_ASSIGNEES_OF_SUBMISSIONS = 'SM_GET_ASSIGNEES_OF_SUBMISSIONS';

export const GET_TAGS_OF_SUBMISSIONS = 'SM_GET_TAGS_OF_SUBMISSIONS';

export const GET_STAGE_OF_SUBMISSIONS = 'SM_GET_STAGE_OF_SUBMISSIONS';

export const GET_PERMISSIONS_OF_SUBMISSIONS = 'GET_PERMISSIONS_OF_SUBMISSIONS';

export const GET_PAYMENTS_OF_SUBMISSIONS = 'GET_PAYMENTS_OF_SUBMISSIONS';

export const GET_GOVOS_PAYMENTS_OF_SUBMISSIONS = 'GET_GOVOS_PAYMENTS_OF_SUBMISSIONS';

export const OPEN_SIDEBAR = 'SM_OPEN_SIDEBAR';

export const TOGGLE_SIDEBAR = 'SM_TOGGLE_SIDEBAR';

export const REMOVE_SUBMISSION = 'SM_REMOVE_SUBMISSION';
export const REQUEST_INITIAL_SUBMISSION_LOAD = 'SM_REQUEST_INITIAL_SUBMISSION_LOAD';
export const REQUEST_SUBSEQUENT_SUBMISSION_LOAD = 'SM_REQUEST_SUBSEQUENT_SUBMISSION_LOAD';

export const SORT_COLUMNS = 'SM_SORT_COLUMNS';

export const SET_HAS_SUBMISSION_STATUS_ERROR = 'SET_HAS_SUBMISSION_STATUS_ERROR';

export const PROCESS_REFUND = 'PROCESS_REFUND';
export const PROCESS_PAYMENT = 'PROCESS_PAYMENT';

export const ARCHIVE_VIEW = 'ARCHIVE_VIEW';

export const TOGGLE_DROPDOWN = 'SM_TOGGLE_DROPDOWN';
export const SET_BULK_ACTION_STATE = 'SET_BULK_ACTION_STATE';
export const FIND_AND_REPLACE_STAGES = 'FIND_AND_REPLACE_STAGES';
export const FIND_AND_REPLACE_TAGS = 'FIND_AND_REPLACE_TAGS';
export const REPLACE_TAGS = 'REPLACE_TAGS';
export const FIND_AND_REPLACE_ASSIGNEES = 'FIND_AND_REPLACE_ASSIGNEES';
export const REPLACE_ASSIGNEES = 'REPLACE_ASSIGNEES';

export const SET_FILTERS_STATE = 'SET_FILTERS_STATE';
export const RESET_FILTERS_STATE = 'RESET_FILTERS_STATE';
export const OPEN_FILTERS_MODAL = 'OPEN_FILTERS_MODAL';
export const CLOSE_FILTERS_MODAL = 'CLOSE_FILTERS_MODAL';
export const ADD_FILTER = 'ADD_FILTER';
export const REMOVE_FILTER = 'REMOVE_FILTER';
export const SELECT_FILTER_COLUMN = 'SELECT_FILTER_COLUMN';
export const SELECT_FILTER_OPERATOR = 'SELECT_FILTER_OPERATOR';
export const CHANGE_FILTER_EXPECTATION = 'CHANGE_FILTER_EXPECTATION';
export const APPLY_FILTERS = 'APPLY_FILTERS';
export const APPLY_STAGE_FILTER = 'APPLY_STAGE_FILTER';
export const REMOVE_STAGE_FILTERS = 'REMOVE_STAGE_FILTERS';
export const RESET_FILTERS = 'RESET_FILTERS';

export const SORT_SUBMISSIONS = 'SORT_SUBMISSIONS';
export const UNSORT_SUBMISSIONS = 'UNSORT_SUBMISSIONS';
export const RESET_SORTING = 'RESET_SORTING';
