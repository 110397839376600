import NewAuthLayout from 'components/AuthLayout/NewAuthLayout';
import { connect } from 'react-redux';
import React, { MouseEventHandler, useEffect, useState } from 'react';
import * as authActions from 'actions/auth';
import {requestPair} from '../../utils/auth';
import { Link } from 'react-router';
import classNames from 'classnames';
interface Props {
  maid: string,
  companyName: string,
  organizationLogo: string,
  isFetching: boolean,
  error: any,
  dispatch: Function,
  companyNames: string,
  location: any,
  router: any,
}

function PairAccount({
  maid,
  companyName,
  organizationLogo,
  isFetching,
  error,
  dispatch,
  location,
  router,
} : Props) {
  const [isPairing, setIsPairing] = useState(false);
  const [paired, setPaired] = useState(false);
  useEffect(() => {
    if (!maid) {
      dispatch(authActions.getAccountKey(dispatch));
    }
  }, [maid]);

  const onPairClicked: MouseEventHandler = async event => {
    event.preventDefault();
    setIsPairing(true);
    const result = await requestPair(maid, location.state.email);
    setIsPairing(false);
    if (result.status === 'OK') {
      // show message and redirect
      setPaired(true);
      setTimeout(() => {
        // redirect to login
        setPaired(false);
        router.replace('ng/login');
      }, 3000);
    }
  };

  const companyNames = [companyName, ...(location?.state?.companyNames || [])].join(', ');
  return (
    <NewAuthLayout
      companyName={companyName}
      logo={organizationLogo}
      subtitle=''
      title='Existing Account'
      fetching={isFetching}
      error={error}
    >
      {paired ? (
        <div style={{color: '#69727C'}}>
          <p>
            Your account has been paired. <br/>
            You are being redirected to login.
          </p>
        </div>
      ) : (
        <>
          <div style={{color: '#69727C'}}>
            <p>The email address <strong>{location.state?.email ?? 'Missing Email'}</strong> is already associated with a user.</p>
            <p>
              Pairing your users allows you to login with a single email
              and your <strong>current password</strong> for all of these accounts:
              &nbsp;<strong>{companyNames}</strong>
            </p>
            <p>
              Do you want to pair accounts? <br />
            </p>
          </div>
          <button className={classNames('authButton', {disabled: isPairing})} disabled={isPairing} onClick={onPairClicked}>Pair User Accounts</button>
          <br/>
          <br/>
          <Link to='/ng/signup'>Back to Signup</Link>
        </>
      )}

    </NewAuthLayout>
  );
}

const mapStateToProps = state => {
  const {
    auth: {
      companyName,
      error,
      isFetching,
      isSubdomainExists,
      maid,
      organizationLogo,
    },
  } = state;
  return {
    companyName,
    error,
    isFetching,
    isSubdomainExists,
    maid,
    organizationLogo,
  };
};
export default connect(mapStateToProps)(PairAccount);
