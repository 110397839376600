import api from 'actions/api';
import * as types from 'constants/types/formActionTypes';
import { ACHPaymentPayload, GovOSPaymentData } from 'types/liveView';
import { Structure } from 'types/richTextEditor/structure';
// import type { GetFormPublicInfoActionCreator } from 'types/forms';

export const getFormPublicInfo = (formId: string, maid: string) => api.postWithoutAuth({
  endpoint: 'forms/publicInfo.json',
  baseType: types.GET_FORM_PUBLIC_INFO,
  data: {
    formId,
    maid,
  },
  success: (_state, { response }) => ({ form: response.result, formId }),
  failure: (_state, error) => ({ error }),
});

export const getFormCoverLetter = (formId: string, maid: string) => api.postWithoutAuth({
  endpoint: 'coverLetterSettingsPublic.json',
  baseType: types.GET_FORM_COVER_LETTER,
  data: {
    formId,
    maid,
  },
  success: (_, { response }) => ({ coverLetter: response, formId }),
  failure: (_, error) => ({ error, formId }),
});

export const getFormLiveView = (formId: string, maid: string, uid: string, formAlias: string) => {
  const data: any = { maid };
  if (formId) {
    data.formId = formId;
  }
  if (formAlias) {
    data.formAlias = formAlias;
  }
  if (uid) {
    data.credentials = { uid, maid };
  }
  return api.postWithAuthIfPresent({
    endpoint: `forms/gate/FormView/formData${formId ? '' : 'ByAlias'}.json`,
    baseType: types.GET_FORM_LIVE_VIEW,
    data,
    success: (_, { response }) => ({ form: response.result, formId }),
    failure: (_, error) => ({ error, formId, formAlias }),
  });
};

export const submitFormLiveView = (
  formId: string,
  maid: string,
  uid: string,
  submissionData: any,
  submissionPayment: any,
  achPayment?: ACHPaymentPayload,
  govOsPaymentData?: GovOSPaymentData,
) => api.postWithoutAuth({
  endpoint: 'submissions/submit.json',
  baseType: types.SUBMIT_FORM_LIVE_VIEW,
  data: {
    formId,
    maid,
    submissionData,
    submissionPayment,
    credentials: { maid, uid },
    achPayment,
    submissionGovOSPayment: govOsPaymentData,
  },
  success: (_, { response }) => ({ submitResult: response.result }),
  failure: (_, error) => ({ error }),
});

export const loadInitiations = formId => api.postWithoutAuth({
  endpoint: 'datasync/data/getInitiations.json',
  data: {formId},
  baseType: types.LOAD_DATA_SYNC_INITIATIONS,
  success: (state, { response }) => response.result,
  failure: (state, error) => ({ error }),
});

export const exchangeFormData = ({formId, initiationId, formData}, callback) => api.postWithoutAuth({
  endpoint: 'datasync/data/exchange.json',
  data: {formId, initiationId, formData},
  baseType: types.EXCHANGE_FORM_DATA,
  success: (state, { response }) => {
    callback?.success(response.result);
    return response.result;
  },
  failure: (state, error) => {
    callback?.failure(error);
  },
});

export const resetLiveViewSubmit = () => ({type: types.SUBMIT_FORM_LIVE_VIEW_RESET});

export const uploadAttachment = (formId: string, file: string | Blob, id: string) => {
  const formData = new FormData();
  formData.append('file', file);

  return api.postWithoutAuth({
    endpoint: `submissions/${formId}/upload`,
    baseType: types.UPLOAD_ATTACHMENT,
    data: formData,
    headers: {},
    success: (_, { response }) => ({ attachment: response.result, id }),
    pending: {pendingFile: file, id},
    failure: (_, error) => ({ error }),
  });
};

export const removeAttachments = (id, attachments) => ({type: types.REMOVE_ATTACHMENTS, payload: {id, attachments}});

export const checkFormPassword = (
  formId: string,
  maid: string,
  password: string,
) =>
  api.postWithoutAuth({
    baseType: types.CHECK_FORM_PASSWORD,
    endpoint: 'form/params/FormView/formPasswordValidation.json',
    data: { formId, passwordToValidate: password, maid },
    pending: () => ({}),
    success: () => ({}),
    failure: (state, error) => ({ error }),
  });

export const toggleIsShareModalOpen = (isShareModalOpen?: boolean) => ({
  type: types.TOGGLE_SHARE_MODAL,
  payload: { isShareModalOpen },
});

export const shareSubmissionFromThankYouPage = (
  formId: string,
  maid: string,
  message: Structure | null,
  recipients: string[],
  subject: string,
  thankYouPageToken: string,
) => api.postWithoutAuth({
  baseType: types.SHARE_SUBMISSION_FROM_THANK_YOU_PAGE,
  endpoint: 'thankYou/shareEmail.json',
  data: {
    formId,
    maid,
    message,
    recipients,
    subject,
    thankYouPageToken,
  },
  success: () => ({}),
  failure: (state, error) => ({ error }),
});

export const getThankYouPagePDFUrl = (tyToken: string) => api.postWithoutAuth({
  baseType: types.GET_THANK_YOU__PAGE_PDF_URL,
  failure: (state, error) => ({error}),
  success: (_, {response}) => ({result: response.result }),
  endpoint: 'submissions/pdfurl.json',
  data: {tyToken},
});
