import _, { union } from 'lodash';
import { createSelector, defaultMemoize } from 'reselect';
import { mergeTables, toggleSortingArrow, decryptionTableHelper } from 'utils/submissionsManager/tableHelper';
import { archive } from 'utils/archive';
import { get, range } from 'lodash';
import { parsedIsEmpty, parsedIsEquivalent } from 'utils/search';
import {SEARCHBAR_INPUT_CHANGE, SHARE_SUBMISSIONS} from 'constants/types/submissionsActionTypes';
import * as types from 'constants/types/submissionsTableActionTypes';
import { GET_USERS } from 'constants/types/userActionTypes';
import { ASSIGN_USERS, ASSIGN_USERS_TO_MULTIPLE, UNASSIGN_USERS, UNASSIGN_USERS_TO_MULTIPLE } from 'constants/types/assignmentsActionTypes';
import { ASSIGN_TAGS, ASSIGN_TAGS_TO_MULTIPLE, UNASSIGN_TAGS, UNASSIGN_TAGS_TO_MULTIPLE } from 'constants/types/tagActionTypes';
import {
  ASSIGN_STAGE,
  UNASSIGN_STAGE,
  UPDATE_STAGE,
  DELETE_STAGE,
  UNASSIGN_STAGES,
  ASSIGN_STAGES,
} from 'constants/types/stageActionTypes';
import {
  ALL_SUBMISSION_FILTER_INITIAL_LOAD,
  ALL_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
  ARCHIVED_SUBMISSION_FILTER_INITIAL_LOAD,
  ARCHIVED_SUBMISSION_FILTER_SUBSEQUENT_LOAD,
} from 'constants/types/submissionsTableActionTypes';
import { SAVE_RULES } from 'constants/types/automatedProcessBuilderActionTypes';
import * as quickFilterTypes from 'constants/types/quickFilterActionTypes';
import { SET_FORM_DETAILS } from 'constants/types/lobby';
import { SEARCH_DEBOUNCE_RATE_MS } from 'constants/tableConsts';
import api from '../api';
import { SelectedRowDataType, SubmissionTableType, TableType } from 'types/submissionsManager/tableTypes';
// import { TriggeredRule } from 'types/automatedProcessBuilder';
import { getFormDetailsFromPayload } from 'utils/formDetails';
import { immutableModifyElementInArray, immutableRemoveElementInArray, immutableInsertElementInArray } from 'utils/reduxHelper';
import { ALL_VIRTUAL_STAGE_NAME } from 'constants/stages';
import { triggersEnum } from 'constants/triggers';
// import { SubmissionTableAction as Action } from 'types/submissionsManager/submissionTableTypes';
import { bulkActionTypes } from 'constants/submissionManager';
import { NUM_OF_SELECTED_SUBMISSIONS_TO_SHOW_BULK_PROCESSING } from 'constants/bulkActions';
import {
  findFilterIndex,
  getEmptyFilter,
  addOrReplaceStageFilter,
  removeStageFilters,
  getStageNameFromFilters,
} from 'utils/submissionsManager/submissionFilters';
import { operatorsEnum } from 'constants/submissionFilters';

// Time the last complete read for new table data was *started*.
let _newTableCompleteStart: Date;
// Time the last complete read for update table data was *started*, by offset.
const _updateTableCompleteStarts: { [offset: number]: Date } = {};

const _isLater = (date: Date, lastCompleteStart: Date): boolean =>
  !(lastCompleteStart && lastCompleteStart.getTime() > date.getTime());

const guardLatestNewTableCompleteStart = (date: Date): boolean => {
  if (_isLater(date, _newTableCompleteStart)) {
    _newTableCompleteStart = date;
    return true;
  }
  return false;
};

const guardLatestUpdateTableCompleteStart = (date: Date, offset: number): boolean => {
  if (_isLater(date, _updateTableCompleteStarts[offset])) {
    _updateTableCompleteStarts[offset] = date;
    return true;
  }
  return false;
};

const initialSortingState = {
  columnKeyBeingSorted: null,
  isAscending: true,
};

export const initialTable: TableType = {
  totalSubmissions: 0,
  totalSubmissionsForFilter: 0,
  size: 0,
  records: [],
  sortingFromServer: [],
  labels: {},
};

export const initialState: SubmissionTableType = {
  error: {},
  failure: false,
  fetching: false,
  fetchingMoreResults: false,
  formId: '',
  formDetails: null,
  headerLabel: '',
  isAllSelected: false,
  isDisplayingSearchResults: false,
  isSearching: false,
  isStagesSidebarExpanded: false,
  isTableSortable: false,
  loadedSubmissions: 0,
  selectedRecordIndices: [],
  selectedRecords: [],
  showSortingArrowIndex: 0,
  snackbarState: {
    changeStage: {},
    isChangeStageNotification: false,
  },
  sortingTargetIndex: 0,
  submissionStatusError: false,
  table: initialTable,
  totalSubmissions: 0,
  totalSubmissionsForFilter: 0,
  archivedCount: 0,
  deletedCount: 0,
  isSubmissionsLoading: false,
  isArchiveView: false,
  isStagesBulkActionsDropdownVisible: false,
  isAssigneesBulkActionsDropdownVisible: false,
  isTagsBulkActionsDropdownVisible: false,
  bulkActionState: {
    isOpened: false,
    modalType: '',
    entityType: '',
  },
  filtersState: {
    isOpened: false,
    filters: [],
    appliedFilters: [],
  },
  sortingState: initialSortingState,
};

const handleFormSwitch = (state: SubmissionTableType): SubmissionTableType => ({
  ...state,
  fetching: true,
  cachedTable: initialState.table,
  cachedTotalSubmissions: 0,
  cachedLoadedSubmissions: 0,
  loadedSubmissions: 0,
  table: initialState.table,
  totalSubmissions: 0,
  totalSubmissionsForFilter: 0,
});

const assignUsersToSubmission = (record: SelectedRowDataType, userIds: string[]) => ({
  ...record,
  assignees: record.assignees ? union(record.assignees, userIds) : userIds,
});

const tagSubmission = (record: SelectedRowDataType, tagNames: string[]) => ({
  ...record,
  tags: record.tags ? union(record.tags, tagNames) : tagNames,
});

const changeSubmissionStage = (record: SelectedRowDataType, stageName: string) => ({
  ...record,
  stageName,
});

// const triggerApbRules = (record: SelectedRowDataType, triggeredRules: TriggeredRule[]) => {
const triggerApbRules = (record: SelectedRowDataType, triggeredRules: any[]) => {
  const updatedRecord = triggeredRules.reduce((accumulator, rule) => {
    const values: string[] = get(rule, ['actionValue', 'values'], []);
    switch (rule.actionType) {
      case triggersEnum.ASSIGNMENT:
        const recordWithAssignments = assignUsersToSubmission(accumulator, values);
        return {
          ...accumulator,
          ...recordWithAssignments,
        };
      case triggersEnum.TAG:
        const recordWithTags = tagSubmission(accumulator, values);
        return {
          ...accumulator,
          ...recordWithTags,
        };
      case triggersEnum.STAGE:
        const recordWithStage = changeSubmissionStage(accumulator, values[0]);
        return {
          ...accumulator,
          ...recordWithStage,
        };
      default:
        return accumulator;
    }
  }, record);
  return updatedRecord;
};

export default function submissionTable(
  state: SubmissionTableType = initialState,
  // action: Action,
  action: any,
): SubmissionTableType {
  switch (action.type) {
    case types.INITIALIZE:
      return {
        ...state,
        formId: action.formId,
      };
    case types.NEW_TABLE_SEARCH_API:
    case ALL_SUBMISSION_FILTER_INITIAL_LOAD:
    case ARCHIVED_SUBMISSION_FILTER_INITIAL_LOAD:
    case quickFilterTypes.ASSIGNED_TO_ME_SUBMISSION_FILTER_INITIAL_LOAD:
    case quickFilterTypes.DRAFTS_SUBMISSION_FILTER_INITIAL_LOAD:
    case quickFilterTypes.SHARED_SUBMISSION_FILTER_INITIAL_LOAD:
    case quickFilterTypes.SUBMITTED_SUBMISSION_FILTER_INITIAL_LOAD:
    case quickFilterTypes.SIGNED_BY_ME_SUBMISSION_FILTER_INITIAL_LOAD:
      return api(action, state, {
        pending: () => {
          if (action.payload.isSearching) {
            return { ...state, isSearching: true };
          }
          return handleFormSwitch(state);
        },
        success: () => {
          if (action.error && guardLatestNewTableCompleteStart(action.requestTime)) {
            return {
              ...state,
              error: action.error,
              fetching: false,
              isSearching: false,
            };
          }
          const { isSearching, ...incomingNewTable } = action.payload;
          const {
            isDisplayingSearchResults,
            totalSubmissions,
            loadedSubmissions,
            table,
            cachedTable,
            cachedTableSortingState,
            cachedTableSubmissionFilters,
            cachedTotalSubmissions,
            cachedLoadedSubmissions,
          } = state;

          // We cache the table when a user is searching so we don't have to fetch it again
          // when they stop searching (i.e., type a search query, get the results, and later remove the query).
          // Therefore, we need don't want to cache a table currently showing search results.
          const isCachingTable = isSearching && !isDisplayingSearchResults;

          if (guardLatestNewTableCompleteStart(incomingNewTable.requestTime)) {
            return {
              ...state,
              totalSubmissions: incomingNewTable.totalSubmissions,
              totalSubmissionsForFilter: incomingNewTable.totalSubmissionsForFilter,
              loadedSubmissions: incomingNewTable.size,
              table: incomingNewTable,
              cachedTable: isCachingTable ? table : cachedTable,
              cachedTotalSubmissions: isCachingTable ? totalSubmissions : cachedTotalSubmissions,
              cachedTableSortingState: isCachingTable
                ? state.sortingState
                : cachedTableSortingState,
              cachedTableSubmissionFilters: isCachingTable ?
                state.filtersState.appliedFilters
                : cachedTableSubmissionFilters,
              cachedLoadedSubmissions: isCachingTable ? loadedSubmissions : cachedLoadedSubmissions,
              fetching: false,
              isSearching: false,
              isDisplayingSearchResults: isSearching ? true : false,
              error: {},
              failure: false,
              formDetails: action.payload.formDetails
                ? getFormDetailsFromPayload(state.formDetails, action.payload.formDetails)
                : state.formDetails,
              selectedRecords: [],
              selectedRecordIndices: [],
              isAllSelected: false,
            };
          }
          return {
            ...state,
            fetching: false,
            isSearching: false,
            error: {},
            failure: false,
          };
        },
        failure: () => ({
          ...state,
          error: action.error,
          fetching: false,
          failure: true,
        }),
      });
    case types.UPDATE_TABLE_SEARCH_API:
    case ALL_SUBMISSION_FILTER_SUBSEQUENT_LOAD:
    case ARCHIVED_SUBMISSION_FILTER_SUBSEQUENT_LOAD:
    case quickFilterTypes.ASSIGNED_TO_ME_SUBMISSION_FILTER_SUBSEQUENT_LOAD:
    case quickFilterTypes.DRAFTS_SUBMISSION_FILTER_SUBSEQUENT_LOAD:
    case quickFilterTypes.SHARED_SUBMISSION_FILTER_SUBSEQUENT_LOAD:
    case quickFilterTypes.SUBMITTED_SUBMISSION_FILTER_SUBSEQUENT_LOAD:
    case quickFilterTypes.SIGNED_BY_ME_SUBMISSION_FILTER_SUBSEQUENT_LOAD:
      return api(action, state, {
        pending: () => {
          if (action.payload.isSearching) {
            return { ...state, isSearching: true };
          }
          return {
            ...state,
            fetching: true,
            fetchingMoreResults: true,
          };
        },
        success: () => {
          if (action.error && guardLatestUpdateTableCompleteStart(action.requestTime, action.offset)) {
            return {
              ...state,
              error: action.error,
              fetching: false,
              isSearching: false,
              fetchingMoreResults: false,
            };
          }
          const { payload } = action;
          if (guardLatestUpdateTableCompleteStart(payload.requestTime, payload.offset)) {
            const newTable = mergeTables(state.table, action.payload);
            return {
              ...state,
              totalSubmissions: newTable.totalSubmissions,
              totalSubmissionsForFilter: newTable.totalSubmissionsForFilter,
              loadedSubmissions: newTable.size,
              failure: false,
              selectedRecords: state.isAllSelected ? newTable.records : state.selectedRecords,
              selectedRecordIndices: action.isAllSelected
                ? range(newTable.records.length)
                : state.selectedRecordIndices,
              table: newTable,
              fetching: false,
              error: {},
              fetchingMoreResults: false,
              isDisplayingSearchResults: payload.isSearching ? true : false,
            };
          }
          return state;
        },
        failure: () => ({
          ...state,
          fetching: false,
          error: action.error,
          failure: true,
          fetchingMoreResults: false,
        }),
      });
    case types.REMOVE_SUBMISSION:
      const { table } = state;
      const updatedTableRecords = table.records.filter(record => record.submissionId !== action.submissionId);
      const delta = table.records.length - updatedTableRecords.length;
      return {
        ...state,
        table: {
          ...table,
          records: updatedTableRecords,
        },
        loadedSubmissions: state.loadedSubmissions - delta,
        totalSubmissionsForFilter: state.totalSubmissionsForFilter - delta,
      };
    case types.USE_CACHED_TABLE:
      // we give timestamp to injecting cached table
      // timestamp delayed by 50ms more because it needs to be greater than debouncing of normal key strokes
      const _now = new Date();
      _newTableCompleteStart = new Date(_now.getTime() + SEARCH_DEBOUNCE_RATE_MS + 50);
      return {
        ...state,
        totalSubmissions: state.cachedTotalSubmissions || 0,
        totalSubmissionsForFilter: state.cachedTotalSubmissions || 0,
        loadedSubmissions: state.cachedLoadedSubmissions || 0,
        table: state.cachedTable || initialState.table,
        sortingState: state.cachedTableSortingState || initialSortingState,
        filtersState: {
          ...state.filtersState,
          appliedFilters: state.cachedTableSubmissionFilters || [],
        },
      };

    case GET_USERS:
      return api(action, state, {
        success: () => ({
          ...state,
        }),
        failure: () => ({
          ...state,
          failure: true,
          error: action.error,
        }),
      });

    case ASSIGN_USERS:
      return api(action, state, {
        success: () => {
          const { triggeredRules, submissionId, userIds } = action.payload;
          const { records } = state.table;
          const recordIndex = records.findIndex(current => current.submissionId === submissionId);
          const record = records[recordIndex];
          if (!record) return state;
          const ruleWithAssignment = assignUsersToSubmission(record, userIds);
          const updatedRecord = triggerApbRules(ruleWithAssignment, triggeredRules);
          const updatedRecords = immutableModifyElementInArray(records, recordIndex, updatedRecord);
          return {
            ...state,
            table: {
              ...state.table,
              records: updatedRecords,
            },
          };
        },
        failure: () => ({
          ...state,
          failure: true,
          error: action.error,
        }),
      });

    case UNASSIGN_USERS:
      return api(action, state, {
        success: () => {
          const { submissionId, userIds } = action.payload;
          const { records } = state.table;
          const recordIndex = records.findIndex(current => current.submissionId === submissionId);
          const record = records[recordIndex];
          if (!record) return state;
          const assignees = record.assignees.filter(assignee => !userIds.includes(assignee));
          const updatedRecord = {
            ...record,
            assignees,
          };
          const updatedRecords = immutableModifyElementInArray(records, recordIndex, updatedRecord);
          return {
            ...state,
            table: {
              ...state.table,
              records: updatedRecords,
            },
          };
        },
        failure: () => ({
          ...state,
          failure: true,
          error: action.error,
        }),
      });

    case ASSIGN_TAGS:
      return api(action, state, {
        success: () => {
          const { submissionId, tagNames, triggeredRules } = action.payload;
          const { records } = state.table;
          const recordIndex = records.findIndex(current => current.submissionId === submissionId);
          const record = records[recordIndex];
          if (!record) return state;
          const recordWithTags = tagSubmission(record, tagNames);
          const updatedRecord = triggerApbRules(recordWithTags, triggeredRules);
          const updatedRecords = immutableModifyElementInArray(records, recordIndex, updatedRecord);
          return {
            ...state,
            table: {
              ...state.table,
              records: updatedRecords,
            },
          };
        },
        failure: () => ({
          ...state,
          failure: true,
          error: action.error,
        }),
      });

    case UNASSIGN_TAGS:
      return api(action, state, {
        success: () => {
          const { submissionId, tagNames } = action.payload;
          const { records } = state.table;
          const recordIndex = records.findIndex(current => current.submissionId === submissionId);
          const record = records[recordIndex];
          if (!record) return state;
          const tags = record.tags.filter(tag => !tagNames.includes(tag));
          const updatedRecord = {
            ...record,
            tags,
          };
          const updatedRecords = immutableModifyElementInArray(records, recordIndex, updatedRecord);
          return {
            ...state,
            table: {
              ...state.table,
              records: updatedRecords,
            },
          };
        },
        failure: () => ({
          ...state,
          failure: true,
          error: action.error,
        }),
      });

    case ASSIGN_STAGE:
      return api(action, state, {
        success: () => {
          const { submissionId, stageName, selectedStageName, triggeredRules } = action.payload;
          const { records } = state.table;
          const recordIndex = records.findIndex(current => current.submissionId === submissionId);
          const record = records[recordIndex];
          if (!record) return state;
          const recordWithStage = changeSubmissionStage(record, stageName);
          const updatedRecord = triggerApbRules(recordWithStage, triggeredRules);
          const updatedRecords =
            selectedStageName !== ALL_VIRTUAL_STAGE_NAME
              ? immutableRemoveElementInArray(records, recordIndex)
              : immutableModifyElementInArray(records, recordIndex, updatedRecord);
          return {
            ...state,
            table: {
              ...state.table,
              records: updatedRecords,
            },
            snackbarState: {
              isSnackbarOpen: true,
              isChangeStageNotification: true,
              changeStage: {
                ...state.snackbarState.changeStage,
                selectedStageName: stageName,
              },
            },
          };
        },
        failure: () => ({
          ...state,
          failure: true,
          error: action.error,
        }),
      });

    case UNASSIGN_STAGE:
      return api(action, state, {
        success: () => {
          const { submissionId, isUnassign, selectedStageName } = action.payload;
          const { records } = state.table;
          const recordIndex = records.findIndex(current => current.submissionId === submissionId);
          const record = records[recordIndex];
          if (!record) return state;
          state.snackbarState.changeStage.oldStageName = record.stageName || '';
          if (!isUnassign) return state;
          const updatedRecord = {
            ...record,
            stageName: null,
          };
          const updatedRecords =
            selectedStageName === record.stageName
              ? immutableRemoveElementInArray(records, recordIndex)
              : immutableModifyElementInArray(records, recordIndex, updatedRecord);
          return {
            ...state,
            table: {
              ...state.table,
              records: updatedRecords,
            },
            snackbarState: {
              ...state.snackbarState,
              isSnackbarOpen: true,
              isChangeStageNotification: true,
            },
          };
        },
        failure: () => ({
          ...state,
          failure: true,
          error: action.error,
        }),
      });

    case DELETE_STAGE:
      return api(action, state, {
        success: () => {
          const { stageName } = action.payload;
          const { records } = state.table;
          const recordsIndexes = records.reduce(
            (acc, record, i) => (stageName === record.stageName ? [...acc, i] : acc),
            [],
          );
          if (!recordsIndexes.length) return state;
          const recordsWithStageNameUpdated = recordsIndexes.map(index => ({
            ...records[index],
            stageName: null,
          }));
          let updatedRecords = records;
          recordsWithStageNameUpdated.forEach((updatedRecord, i) => {
            updatedRecords = immutableModifyElementInArray(updatedRecords, recordsIndexes[i], updatedRecord);
          });
          return {
            ...state,
            table: {
              ...state.table,
              records: updatedRecords,
            },
          };
        },
        failure: () => ({
          ...state,
          failure: true,
          error: action.error,
        }),
      });
    case UPDATE_STAGE:
      return api(action, state, {
        success: () => {
          const {
            oldStageName,
            newStage: { name },
          } = action.payload;
          const { records } = state.table;
          const recordsIndexes = records.reduce(
            (acc, { stageName }, i) => (stageName === oldStageName ? [...acc, i] : acc),
            [],
          );
          if (!recordsIndexes.length) return state;
          const recordsWithStageNameUpdated = recordsIndexes.map(index => ({
            ...records[index],
            stageName: name,
          }));
          let updatedRecords = records;
          recordsWithStageNameUpdated.forEach((updatedRecord, i) => {
            updatedRecords = immutableModifyElementInArray(updatedRecords, recordsIndexes[i], updatedRecord);
          });
          return {
            ...state,
            table: {
              ...state.table,
              records: updatedRecords,
            },
          };
        },
        failure: () => ({
          ...state,
          failure: true,
          error: action.error,
        }),
      });

    case types.GET_ASSIGNEES_OF_SUBMISSIONS:
      return api(action, state, {
        success: () => ({
          ...state,
          table: {
            ...state.table,
            records: state.table.records.map(record => {
              const assignees = action.payload[record.submissionId];
              if (!assignees) return record;
              return {
                ...record,
                assignees,
              };
            }),
          },
        }),
      });

    case types.GET_TAGS_OF_SUBMISSIONS:
      return api(action, state, {
        success: () => ({
          ...state,
          table: {
            ...state.table,
            records: state.table.records.map(record => {
              const tags = action.payload.submissionsToTags[record.submissionId];
              if (!tags) {
                const submission = action.payload.submissionIds.find(id => id === record.submissionId);
                if (!submission) return record;
                return {
                  ...record,
                  tags: [],
                };
              }
              return {
                ...record,
                tags: tags.map(tag => tag.name),
              };
            }),
          },
        }),
      });

    case types.GET_STAGE_OF_SUBMISSIONS:
      return api(action, state, {
        success: () => ({
          ...state,
          table: {
            ...state.table,
            records: state.table.records.map(record => {
              const stages = action.payload.submissionsToStage[record.submissionId];
              if (!stages) {
                const submission = action.payload.submissionIds.find(id => id === record.submissionId);
                if (!submission) return record;
                return {
                  ...record,
                  stageName: '',
                };
              }
              return {
                ...record,
                stageName: stages[0].name,
              };
            }),
          },
        }),
      });

    case types.GET_PAYMENTS_OF_SUBMISSIONS:
      return api(action, state, {
        success: () => ({
          ...state,
          isSubmissionsLoading: false,
          table: {
            ...state.table,
            records: state.table.records.map(record => {
              const payments =
                action.payload[record.submissionId] &&
                _.flatten(_.flatten(action.payload[record.submissionId])).filter(_.isObject);
              if (!payments) return record;
              return {
                ...record,
                payments,
              };
            }),
          },
        }),

        failure: () => ({ ...state, isSubmissionsLoading: false }),
        pending: () => ({ ...state, isSubmissionsLoading: true }),
      });

    case types.GET_GOVOS_PAYMENTS_OF_SUBMISSIONS:
      return api(action, state, {
        success: () => ({
          ...state,
          isSubmissionsLoading: false,
          table: {
            ...state.table,
            records: state.table.records.map(record => {
              const govOsPayments =
                  action.payload[record.submissionId] &&
                  _.flatten(_.flatten(action.payload[record.submissionId]))
                    .filter(_.isObject)
                    .map(payment => ({
                      ...payment,
                      integrationType: 'govos',
                    }));
              if (!govOsPayments) return record;
              return {
                ...record,
                govOsPayments,
              };
            }),
          },
        }),

        failure: () => ({ ...state, isSubmissionsLoading: false }),
        pending: () => ({ ...state, isSubmissionsLoading: true }),
      });

    case types.SET_HAS_SUBMISSION_STATUS_ERROR:
      return { ...state, submissionStatusError: action.isError };

    case types.GET_PERMISSIONS_OF_SUBMISSIONS:
      return api(action, state, {
        success: () => ({
          ...state,
          table: {
            ...state.table,
            records: state.table.records.map(record => {
              const permissions = action.payload[record.submissionId];
              if (!permissions) return record;
              return {
                ...record,
                permissions,
              };
            }),
          },
        }),
      });

    case SEARCHBAR_INPUT_CHANGE:
      const isSearchInit: boolean = parsedIsEmpty(action.searchbarInput);
      const isSearchInputChanging: boolean = state.searchbarInput
        ? !parsedIsEquivalent(action.searchbarInput, state.searchbarInput)
        : false;
      return {
        ...state,
        searchbarInput: action.searchbarInput,
        isSearching: !(isSearchInit || !isSearchInputChanging),
      };
    case types.SELECT_TABLE_RECORD:
      let selectedRecords;
      let selectedRecordIndices;
      if (action.isChecked) {
        selectedRecords = [...state.selectedRecords, action.submission];
      } else {
        selectedRecords = state.selectedRecords.filter(
          submission => submission.submissionId !== action.submission.submissionId,
        );
      }
      if (action.isChecked) {
        selectedRecordIndices = [...state.selectedRecordIndices, action.index];
      } else {
        selectedRecordIndices = state.selectedRecordIndices.filter(index => index !== action.index);
      }
      return {
        ...state,
        selectedRecords,
        selectedRecordIndices,
      };
    case types.SELECT_ALL_RECORDS:
      return {
        ...state,
        selectedRecords: action.isAllSelected ? state.table.records : [],
        selectedRecordIndices: action.isAllSelected ? range(state.table.records.length) : [],
        isAllSelected: action.isAllSelected,
      };

    case types.COLUMN_ORDER_API:
      return api(action, state, {
        pending: () => ({
          ...state,
          table: {
            ...state.table,
            sortingFromServer: action.payload.sorting,
          },
          isTableSortable: false,
        }),
        failure: () => ({
          ...state,
          table: {
            ...state.table,
            sortingFromServer: action.payload.sorting,
          },
          snackbarState: {
            ...state.snackbarState,
            isSnackbarOpen: true,
            isChangeStageNotification: false,
            snackbarMessage: 'Column Reordering Failed',
          },
        }),
      });
    case types.DECRYPT_FIELD_API:
      return api(action, state, {
        success: () => ({
          ...state,
          table: {
            ...state.table,
            records: state.table.records.map((record, index) =>
              index === action.payload.rowIndex ? decryptionTableHelper(record, action) : record,
            ),
          },
        }),
        failure: () => ({
          ...state,
          snackbarState: {
            ...state.snackbarState,
            isSnackbarOpen: true,
            isChangeStageNotification: false,
            snackbarMessage: 'Field Value Decryption Failed',
          },
        }),
      });
    case types.MAKE_SORTABLE:
      return {
        ...state,
        isTableSortable: !state.isTableSortable,
        sortingTargetIndex: action.payload.sortingTargetIndex,
        showSortingArrowIndex: toggleSortingArrow(action.payload, state),
        headerLabel: action.payload.headerLabel,
      };

    case types.FORM_DETAILS:
      return api(action, state, {
        success: () => ({
          ...state,
          formDetails: getFormDetailsFromPayload(state.formDetails, action.payload),
        }),
      });
    // Action from Lobby Action, to set form details on a form click
    case SET_FORM_DETAILS:
      return api(action, state, {
        success: () => ({
          ...state,
          formDetails: getFormDetailsFromPayload(state.formDetails, action.payload),
        }),
      });
    case types.SUBMISSIONS_STATS:
      return api(action, state, {
        success: () => ({
          ...state,
          archivedCount: action.payload.archivedCount,
          deletedCount: action.payload.deletedCount,
        }),
      });
    case types.ARCHIVE_SUBMISSION_API:
    case types.DELETE_ARCHIVED_SUBMISSION_API:
      return api(action, state, {
        pending: () => {
          const { selectedRecordIndices: checkedIndex, selectedRowIndex } = action.payload;
          const isCheckboxed = checkedIndex && checkedIndex.length > 0;
          return {
            ...state,
            archivingType: isCheckboxed ? 'checkbox' : 'select',
            archivingCheckedIndex: isCheckboxed ? checkedIndex : null,
            archivingSelectedIndex: isCheckboxed ? null : selectedRowIndex,
          };
        },
        success: () => {
          const { active: updatedStateTableRecords } = archive(
            'submissionId',
            action.payload.selectedRowData,
            state.table.records,
            action.payload.selectedRecords,
          );
          const {active: updatedCacheTableRecords} = archive(
            'submissionId',
            action.payload.selectedRowData,
            state?.cachedTable?.records,
            action.payload.selectedRecords,
          );
          const totalSubmissions = state.totalSubmissions - action.payload.selectedRecords.length;
          const totalSubmissionsForFilter = state.totalSubmissions - action.payload.selectedRecords.length;
          const deletedCount = state.deletedCount + action.payload.selectedRecords.length;
          const cachedTotalSubmissions = (state.cachedTotalSubmissions ?? 0) - action.payload.selectedRecords.length;
          const cachedLoadedSubmissions = (state.cachedLoadedSubmissions ?? 0) - action.payload.selectedRecords.length;
          return {
            ...state,
            totalSubmissions,
            totalSubmissionsForFilter,
            cachedTotalSubmissions,
            cachedLoadedSubmissions,
            selectedRecords: [],
            selectedRecordIndices: [],
            isAllSelected: false,
            table: {
              ...state.table,
              records: updatedStateTableRecords,
            },
            cachedTable: {
              ...state.cachedTable,
              records: updatedCacheTableRecords,
            },
            archivingType: '',
            archivingCheckedIndex: null,
            archivingSelectedIndex: null,
            snackbarState: {
              ...state.snackbarState,
              isSnackbarOpen: true,
              isChangeStageNotification: false,
              snackbarMessage: action.payload.snackbarMessage,
            },
            deletedCount,
          };
        },
      });
    case SAVE_RULES:
      return api(action, state, {
        success: () => ({
          ...state,
          snackbarState: {
            ...state.snackbarState,
            isSnackbarOpen: true,
            isChangeStageNotification: false,
            snackbarMessage: action.payload.successMessage,
          },
        }),
        failure: () => ({
          ...state,
          snackbarState: {
            ...state.snackbarState,
            isSnackbarOpen: !!action.payload.errorMessage,
            isChangeStageNotification: false,
            snackbarMessage: action.payload.errorMessage,
          },
        }),
      });
    case types.RESET_TABLE_SNACKBAR:
      return {
        ...state,
        snackbarState: {
          ...state.snackbarState,
          isSnackbarOpen: false,
          snackbarMessage: '',
          changeStage: {},
        },
      };
    case types.TOGGLE_SNACKBAR:
      return {
        ...state,
        snackbarState: {
          ...state.snackbarState,
          isSnackbarOpen: action.snackbarOpen,
          snackbarMessage: action.snackbarMessage,
        },
      };
    case types.EXPORT_SUBMISSION_API:
      return api(action, state, {
        success: () => ({
          ...state,
        }),
      });
    case types.RESET_SELECTED_ROWS:
      return {
        ...state,
        selectedRecords: [],
        selectedRecordIndices: [],
        isAllSelected: false,
      };
    case types.OPEN_SIDEBAR:
      return {
        ...state,
        isStagesSidebarExpanded: true,
      };
    case types.TOGGLE_SIDEBAR:
      return {
        ...state,
        isStagesSidebarExpanded: !state.isStagesSidebarExpanded,
      };
    case types.TOGGLE_DROPDOWN:
      switch (action.dropdownId) {
        case bulkActionTypes.Stages:
          return {
            ...state,
            isStagesBulkActionsDropdownVisible:
              action.value ? action.value : !state.isStagesBulkActionsDropdownVisible,
          };
        case bulkActionTypes.Assignees:
          return {
            ...state,
            isAssigneesBulkActionsDropdownVisible:
              action.value ? action.value : !state.isAssigneesBulkActionsDropdownVisible,
          };
        case bulkActionTypes.Tags:
          return {
            ...state,
            isTagsBulkActionsDropdownVisible:
              action.value ? action.value : !state.isTagsBulkActionsDropdownVisible,
          };
        default:
          return state;
      }
    case types.SET_BULK_ACTION_STATE:
      return {
        ...state,
        bulkActionState: action.payload,
      };
    case types.SET_FILTERS_STATE:
      return {
        ...state,
        filtersState: action.payload,
      };
    case types.RESET_FILTERS_STATE:
      return {
        ...state,
        filtersState: {
          isOpened: false,
          filters: [],
          appliedFilters: [],
        },
      };
    case types.OPEN_FILTERS_MODAL:
      return {
        ...state,
        filtersState: {
          ...state.filtersState,
          filters: state.filtersState.appliedFilters,
          isOpened: true,
        },
      };
    case types.CLOSE_FILTERS_MODAL:
      return {
        ...state,
        filtersState: {
          ...state.filtersState,
          isOpened: false,
        },
      };
    case types.ADD_FILTER:
      // If the filter is added when the modal is closed (via the column header)
      // we get the initial state from the already applied filters
      const filtersBeforeAdding = (state.filtersState.isOpened)
        ? state.filtersState.filters
        : state.filtersState.appliedFilters;
      const newFilter = action.payload || getEmptyFilter();
      return {
        ...state,
        filtersState: {
          ...state.filtersState,
          isOpened: true,
          filters: immutableInsertElementInArray(filtersBeforeAdding, filtersBeforeAdding.length, newFilter),
        },
      };
    case types.REMOVE_FILTER:
      const filterToRemoveIndex = findFilterIndex(action.payload, state.filtersState);
      if (_.isNumber(filterToRemoveIndex) && filterToRemoveIndex >= 0) {
        return {
          ...state,
          filtersState: {
            ...state.filtersState,
            filters: immutableRemoveElementInArray(state.filtersState.filters, filterToRemoveIndex),
          },
        };
      }
      return state;
    case types.SELECT_FILTER_COLUMN:
      const filterToModifyColumnIndex = findFilterIndex(action.payload.id, state.filtersState);
      if (_.isNumber(filterToModifyColumnIndex) && filterToModifyColumnIndex >= 0) {
        return {
          ...state,
          filtersState: {
            ...state.filtersState,
            filters: immutableModifyElementInArray(state.filtersState.filters, filterToModifyColumnIndex, {
              ...state.filtersState.filters[filterToModifyColumnIndex],
              columnKey: action.payload.columnKey,
            }),
          },
        };
      }
      return state;
    case types.SELECT_FILTER_OPERATOR:
      const filterToModifyOperatorIndex = findFilterIndex(action.payload.id, state.filtersState);
      if (_.isNumber(filterToModifyOperatorIndex) && filterToModifyOperatorIndex >= 0) {
        return {
          ...state,
          filtersState: {
            ...state.filtersState,
            filters: immutableModifyElementInArray(state.filtersState.filters, filterToModifyOperatorIndex, {
              ...state.filtersState.filters[filterToModifyOperatorIndex],
              operator: action.payload.operator,
            }),
          },
        };
      }
      return state;
    case types.CHANGE_FILTER_EXPECTATION:
      const filterToModifyExpectationIndex = findFilterIndex(action.payload.id, state.filtersState);
      if (_.isNumber(filterToModifyExpectationIndex) && filterToModifyExpectationIndex >= 0) {
        return {
          ...state,
          filtersState: {
            ...state.filtersState,
            filters: immutableModifyElementInArray(state.filtersState.filters, filterToModifyExpectationIndex, {
              ...state.filtersState.filters[filterToModifyExpectationIndex],
              expectation: action.payload.expectation,
            }),
          },
        };
      }
      return state;
    case types.APPLY_FILTERS:
      return {
        ...state,
        filtersState: {
          ...state.filtersState,
          appliedFilters: action.payload,
          isOpened: false,
        },
      };
    case types.APPLY_STAGE_FILTER:
      const updatedFilters = addOrReplaceStageFilter(state.filtersState.appliedFilters, action.payload.stageName);
      return {
        ...state,
        filtersState: {
          ...state.filtersState,
          appliedFilters: updatedFilters,
        },
      };
    case types.REMOVE_STAGE_FILTERS:
      return {
        ...state,
        filtersState: {
          ...state.filtersState,
          appliedFilters: removeStageFilters(state.filtersState.appliedFilters),
        },
      };
    case types.RESET_FILTERS:
      return {
        ...state,
        filtersState: initialState.filtersState,
      };
    case types.SORT_SUBMISSIONS:
      return {
        ...state,
        sortingState: action.newSortingState,
      };
    case types.UNSORT_SUBMISSIONS:
      return {
        ...state,
        sortingState: {
          columnKeyBeingSorted: null,
          isAscending: true,
        },
      };
    case types.RESET_SORTING:
      return {
        ...state,
        sortingState: initialState.sortingState,
      };
    default:
      return state;
    case types.ARCHIVE_VIEW:
      return {...state, isArchiveView: action.isArchiveView};
    case ASSIGN_STAGES:
    case ASSIGN_USERS_TO_MULTIPLE:
    case UNASSIGN_USERS_TO_MULTIPLE:
    case UNASSIGN_STAGES:
    case ASSIGN_TAGS_TO_MULTIPLE:
    case UNASSIGN_TAGS_TO_MULTIPLE:
    case types.FIND_AND_REPLACE_STAGES:
    case types.FIND_AND_REPLACE_TAGS:
    case types.FIND_AND_REPLACE_ASSIGNEES:
    case types.REPLACE_TAGS:
    case types.REPLACE_ASSIGNEES:
    case SHARE_SUBMISSIONS:
      return api(action, state, {
        success: () => {
          const snackbarState = {
            ...state.snackbarState,
            isSnackbarOpen: action.payload.numOfSelected < NUM_OF_SELECTED_SUBMISSIONS_TO_SHOW_BULK_PROCESSING,
            snackbarMessage: action.payload.snackbarMessage,
            isChangeStageNotification: false,
          };
          const { triggeredRules, submissionIds } = action.payload;

          if (!triggeredRules || !triggeredRules.length || !submissionIds || !submissionIds.length) {
            return {
              ...state,
              snackbarState,
            };
          }
          const { records } = state.table;
          const updatedRecords = records.slice();
          submissionIds.forEach(id => {
            const recordIndex = records.findIndex(current => current.submissionId === id);
            const record = records[recordIndex];
            const updatedRecord = triggerApbRules(record, triggeredRules);
            updatedRecords[recordIndex] = updatedRecord;
          });
          return {
            ...state,
            snackbarState,
            table: {
              ...state.table,
              records: updatedRecords,
            },
          };
        },
      });
  }
}

export const getSubmissionManagerFormId = (state: SubmissionTableType) => state.formId;

export const getSubmissionManagerFormDetails = (state: SubmissionTableType) => state.formDetails;

export const getTotalSubmissionsCount = (state: SubmissionTableType) => state.totalSubmissions;

export const getLoadedSubmissionCount = (state: SubmissionTableType) => state.loadedSubmissions;

export const getTotalSubmissionsForFilterCount = (state: SubmissionTableType) => state.totalSubmissionsForFilter;

export const getSubmissionTable = defaultMemoize((state: SubmissionTableType) => state.table);

export const getSortingFromServer = createSelector([getSubmissionTable], table =>
  table.sortingFromServer);

export const getSubmissionTableLabels = createSelector([getSubmissionTable], table => table.labels);

export const getHasStatusError = (state: SubmissionTableType) => state.submissionStatusError;

export const getIsArchivedView = (state: SubmissionTableType) => state.isArchiveView;

export const checkSubmissionsCapability = (state: SubmissionTableType, capability) => state &&
  state.table && state.table.records && state.table.records.length &&
  state.table.records[0].permissions && state.table.records[0].permissions.includes(capability);

export const getFiltersState = (state: SubmissionTableType) => state.filtersState;

export const getSubmissionFilters = createSelector([getFiltersState], filtersState => filtersState.filters);

export const getAppliedSubmissionFilters =
  createSelector([getFiltersState], filtersState => filtersState.appliedFilters);

export const getIsSubmissionFilterApplied = createSelector([getAppliedSubmissionFilters], appliedFilters =>
  appliedFilters.length > 0);

export const getSelectedStageName = createSelector([getAppliedSubmissionFilters], getStageNameFromFilters);

export const getSortingState = (state: SubmissionTableType) => state.sortingState;

export const getIsFiltersStateValid = (state: SubmissionTableType): boolean =>
  state.filtersState.filters.every(filter =>
    filter.id && filter.columnKey && filter.operator &&
    (filter.operator !== operatorsEnum.hasAValue && filter.operator !== operatorsEnum.hasNoValue ?
      !!filter.expectation : true));

export const getSubmissionPaginationToken = createSelector([getSubmissionTable], table => table.nextPageToken || null);
