import React, {forwardRef, useEffect} from 'react';
import { Helmet } from 'react-helmet';
import { css } from '../GoogleTranslate/GoogleTranslateStyles';

declare global {
  interface Window {google: any, googleTranslateElementInit: any}
}

const googleTranslateElementInit = () => {
  window.google.translate.TranslateElement({
    pageLanguage: 'en',
    autoDisplay: false,
    layout: window.google.translate.TranslateElement.InlineLayout.SIMPLE },
  'google_translate_element');
};

const GoogleTranslate = () => {
  useEffect(() => {
    window.googleTranslateElementInit = googleTranslateElementInit;
  }, []);

  return (<div id='google_translate_element'>
    <Helmet>
      <script src='//translate.google.com/translate_a/element.js?cb=googleTranslateElementInit' />
      <style type='text/css'>{css}</style>
    </Helmet>
  </div>);
};

export default React.memo(forwardRef(GoogleTranslate));
