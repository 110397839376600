import {createGlobalStyle} from 'styled-components';

export const pxToNumber = (px: string) => parseFloat(px.slice(0, -2));

export const buildFormCompStyle = (id, properties) => createGlobalStyle`
    .${id}-font-settings {
        font-family: ${properties?.fontFamily};
        font-size: ${properties?.fontSize}px;
        font-style: ${properties?.fontStyle};
        font-weight: ${properties?.fontWeight};
        letter-spacing: ${properties?.letterSpacing?.match(/^\d+$/) ? properties?.letterSpacing + 'px' : properties?.letterSpacing};
        line-height: ${properties?.lineHeight?.match(/^\d+$/) ? properties?.letterSpacing + 'px' : properties?.lineHeight};
        text-align: ${properties?.textAlign};
        text-decoration: ${properties?.textDecoration};
        word-spacing: ${properties?.wordSpacing?.match(/^\d+$/) ? properties?.letterSpacing + 'px' : properties?.wordSpacing};
        color: ${properties?.color};
    }
`;
