import * as env from '../env';
import { LOBBY_ROOT_FOLDER } from 'constants/tableConsts';

export const formatSubdomain = (subdomain: string, environment = env.AUTH0_ENVIRONMENT) =>
  [subdomain, environment].filter(Boolean).join('.');

export const formatLegacyEditFormUrl = form => {
  if (!form) return null;

  let redirectPath = '';
  if (form.itemType === 'Webform') {
    redirectPath = `/forms/builder/${form.formId}`;
  } else if (form.itemType === 'Wizard') {
    redirectPath = `/w/${form.alias}/admin`;
  } else {
    redirectPath = `/f/${form.alias}/admin`;
  }

  return redirectPath;
};

export const formatLegacyLiveviewUrl = (form: { alias: string}) => `/f/${form.alias}`;

export const formCenter = () => '/sc/forms?fromNG=true';

export const grmSignup = () => '/sc/signup';


export const GRM = (parentFolderId: string, formId: string) => {
  const folderId = parentFolderId == null || parentFolderId === LOBBY_ROOT_FOLDER ? 'root' : parentFolderId;
  return `/sc/${folderId}/${formId}?fromNG=true`;
};

export const grmPipeline = (formId: string) => `/sc/pipeline/${formId}`;

export const grmSubmission = (formId: string, submissionId: string) => `/sc/pipeline/${formId}/${submissionId}`;

export const grmLobby = () => '/sc/lobby/';

export const loginInterstitialPath = (queryString: string, basePath = 'ng') =>
  `/${basePath}/loginToViewForm${queryString}`;

export const registrationInterstitialPath = (queryString: string) => `/ng/registration${queryString}`;

export const redirectToLogin = (router: any) => router.replace('/ng/login');

export const redirectToNotFound = () => {window.location.href = '/ng/404';};

export const formatLiveviewUrl = (form: { alias: string}) => `/ng/fa/${form.alias}`;

export const deployment = window.location.hostname.split('.')[1];

export const continueLink = (form: { alias: string; }, submissionId: string) =>
  `${formatLegacyLiveviewUrl(form)}/${btoa(submissionId)}`;

