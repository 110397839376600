import React, { forwardRef, useState, useImperativeHandle, useEffect, useMemo } from 'react';
import DatePicker from 'react-datepicker';
import classNames from 'classnames';
import moment from 'moment';
import sharedStyle from '../shared.css';
import formInputStyle from '../FormInput/FormInput.css';
import ErrorContainer from '../ErrorContainer/ErrorContainer';
import 'react-datepicker/dist/react-datepicker-cssmodules.css';
import {buildFormCompStyle} from 'utils/css';
import { ControlState, ShowLabelValue } from 'types/liveView';
import { showLabelToBoolean } from 'utils/formLiveView/formLiveView';

type ServerDateFormat = 'FdY' | 'mdY' | 'mY' | 'dmY' | 'Ymd';

const dateModifiers = ['d', 'w', 'M', 'y'];
const getRelativeDate = modifier => modifier ?
  moment().add(
    parseInt(modifier, 10),
    dateModifiers.includes(modifier.slice(-1)) ? modifier.slice(-1) : 'd'
  ).toDate() :
  null;
const getDateFormat = (dateFormat: ServerDateFormat): string => {
  switch (dateFormat) {
    case 'mdY':
      return 'MM/dd/yyyy';
    case 'mY':
      return 'MM/yyyy';
    case 'dmY':
      return 'dd/MM/yyyy';
    case 'Ymd':
      return 'yyyy-MM-dd';
    case 'FdY':
    default:
      return 'MMMM d, yyyy';
  }
};

export type Props = {
    id: string,
    label: string,
    hoverText: string,
    onChange: Function | null,
    updateForm: Function | null,
    cssClass: string,
    labelCssClass: string,
    fieldState?: ControlState | null,
    extraData?: {
      alignment?: string,
      dateFormat: ServerDateFormat,
      label?: string,
      labelAlign?: string,
      maxLimit?: string,
      minLimit?: string,
      showLabel?: ShowLabelValue,
    },
}

function FormDatePicker({
  id,
  label,
  hoverText,
  onChange = null,
  updateForm = null,
  cssClass = sharedStyle.FormControlGroup,
  labelCssClass = sharedStyle.FormLabel,
  fieldState = null,
  extraData = {
    showLabel: 't',
    dateFormat: 'FdY',
  },
  properties = {} as any,
  ...props
}: Props & Partial<any>, ref: React.Ref<unknown>) {
  const { minLimit, maxLimit, dateFormat } = extraData;
  const minDate = useMemo(() => getRelativeDate(minLimit), [minLimit]);
  const maxDate = useMemo(() => getRelativeDate(maxLimit), [maxLimit]);

  const extraProps = {
    maxDate,
    minDate,
  };

  const [value, setValue] = useState(fieldState?.fields?.selected as Date);
  const [error, setError] = useState('');
  const [touched, setTouched] = useState(false);

  const selfValidate = () => {
    if (props.required && !value) {
      setError('This field is required.');
      return false;
    }

    setError('');
    return true;
  };

  useEffect(() => {
    touched && selfValidate();
  }, [value]);

  useImperativeHandle(
    ref,
    () => ({
      validate: selfValidate,
    }));

  const onDateChanged = (selected: Date) => {
    setValue(selected);
    setTouched(true);
    onChange && onChange(selected);
    updateForm && updateForm({
      fields: { selected },
    });
  };

  const onDateChangedRaw = (e: React.KeyboardEvent) => { // ENG-2570: make sure typing is disabled
    e.preventDefault();
  };

  const CustomCompStyle = buildFormCompStyle(id, properties);

  return (
    <div id={`container_${id}`} title={hoverText} className={classNames(cssClass, 'form_control_group')}>
      <CustomCompStyle />
      {showLabelToBoolean(extraData?.showLabel)
        ? (
          <label
            htmlFor={id}
            className={classNames(labelCssClass, { [sharedStyle.Required]: props.required }, 'datepicker_label')}>
            {label}
          </label>
        )
        : null
      }
      <ErrorContainer error={error}>
        <DatePicker
          selected={value}
          onChangeRaw={onDateChangedRaw}
          inputProps={{readOnly: true}}
          onChange={onDateChanged}
          className={classNames(formInputStyle.FormInput, 'datepicker_input', `${id}-font-settings`)}
          id={id}
          autoComplete='off'
          showYearDropdown
          placeholderText={(extraProps as any).placeholder || 'Click to select a date'}
          dateFormat={getDateFormat(dateFormat)}
          {...props}
          {...extraProps}
        />
      </ErrorContainer>
    </div>
  );
}

export default React.memo(forwardRef(FormDatePicker));
