export const GET_FORM_PUBLIC_INFO = 'GET_FORM_PUBLIC_INFO';
export const GET_FORM_LIVE_VIEW = 'GET_FORM_LIVE_VIEW';
export const SUBMIT_FORM_LIVE_VIEW = 'SUBMIT_FORM_LIVE_VIEW';
export const LOAD_DATA_SYNC_INITIATIONS = 'LOAD_DATA_SYNC_INITIATIONS';
export const EXCHANGE_FORM_DATA = 'EXCHANGE_FORM_DATA';
export const SUBMIT_FORM_LIVE_VIEW_RESET = 'SUBMIT_FORM_LIVE_VIEW_RESET';
export const GET_FORM_COVER_LETTER = 'GET_FORM_COVER_LETTER';
export const UPLOAD_ATTACHMENT = 'UPLOAD_ATTACHMENT';
export const REMOVE_ATTACHMENTS = 'REMOVE_ATTACHMENTS';
export const RESET_FORM_LIVE_VIEW = 'RESET_FORM_LIVE_VIEW';
export const CHECK_FORM_PASSWORD = 'CHECK_FORM_PASSWORD';
export const TOGGLE_SHARE_MODAL = 'TOGGLE_SHARE_MODAL';
export const SHARE_SUBMISSION_FROM_THANK_YOU_PAGE = 'SHARE_SUBMISSION_FROM_THANK_YOU_PAGE';
export const GET_THANK_YOU__PAGE_PDF_URL = 'GET_THANK_YOU__PAGE_PDF_URL';
