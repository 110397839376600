import React from 'react';
// import { reNotifyRequest } from 'actions/submissionsManager/tableApi';
import Sidebar from 'components/Sidebar';
import SidebarDetails from 'components/SidebarDetails';
import SubmissionDetails from 'containers/SubmissionsManager/SubmissionDetails';
import Loader from 'components/Loader';
import styles from './SubmissionManagerSidebar.css';
import { getFormattedTime, TIME_FORMAT_LONG } from 'utils/dateHelper';
import * as applicationCopy from 'constants/applicationCopy';

export default class SubmissionManagerSidebar extends React.Component<any, any> {
  renderHeaderContent = () => {
    const { submission } = this.props;
    const modifiedTs = submission ? submission.modifiedTs : '';
    return (
      <>
        <p className={styles.headerText}>Submission</p>
        <span className={styles.headlineDetails}>Last Activity: {getFormattedTime(modifiedTs, TIME_FORMAT_LONG)}</span>
      </>
    );
  };

  onEditSignerModalToggle = () => {
    this.props.editSignerVisibility(!this.props.editSignerVisible);
  };

  onRefundModalToggle = () => {
    this.props.refundVisibility(!this.props.refundVisible);
  };

  onSignerDetailsToggle = () => {
    this.props.signerDetailsVisibility(!this.props.signerDetailsVisible);
  };

  handleOpenDecryptionModal = (rawValue, rowIndex, inputName) => {
    this.props.decryptModalVisibility(rawValue, rowIndex, inputName);
  };
  handleReNotify = (signerKey, email) => {
    const { formDetails, loaderModalToggle, recordDetails, renotify } = this.props;
    loaderModalToggle(
      true,
      'loading',
      applicationCopy.SEND_NOTIFICATION_LOADING_TITLE,
      applicationCopy.SEND_NOTIFICATION_LOADING_BODY(email)
    );
    if (formDetails && recordDetails && recordDetails.submissionId) {
      renotify(formDetails.id, recordDetails.submissionId, signerKey, email);
    }
  };

  handleSignNow = ({ signerKey, signeeType, fullname, email }) => {
    const { submissionId, formId } = this.props.recordDetails;

    const signerData = {
      application_id: submissionId,
      signer: {
        key: signerKey,
        recipient_type: signeeType ? signeeType.toLowerCase() : 'signer',
        recipient_info: {
          fullname,
          email,
        },
      },
    };

    const url = '/f/' + formId + '/' + btoa(JSON.stringify(signerData));

    window.open(url, '_blank');
  }

  render() {
    const {
      editSignerVisible,
      hideSidebar,
      isMobile,
      isOpen,
      isVisible,
      recordDetails,
      refundVisible,
      selectedRowIndex,
      signerDetailsVisible,
      sortedFilteredColumns,
      submission,
      trackDownloadFile,
      usableWindowWidth,
      shouldRenderLoader,
      maid,
    } = this.props;

    const sidebarWidthPercentage = isMobile ? 100 : null;

    return (
      <Sidebar
        dockerPoint={usableWindowWidth}
        visible={isVisible}
        open={isOpen}
        onSidebarToggle={hideSidebar}
        renderHeaderContent={this.renderHeaderContent}
        widthPercentage={sidebarWidthPercentage}
        shouldRenderClose
        shouldRenderHeader
      >
        {!recordDetails && <Loader />}
        {recordDetails && (
          <SidebarDetails
            formId={recordDetails.formId}
            submissionId={recordDetails.submissionId}
            recordDetails={recordDetails}
            maid={maid}
          >
            <SubmissionDetails
              shouldRenderLoader={shouldRenderLoader}
              selectedRowData={submission}
              selectedRowIndex={selectedRowIndex}
              recordDetails={recordDetails}
              refundVisible={refundVisible}
              onRefundModalToggle={this.onRefundModalToggle}
              onEditSignerModalToggle={this.onEditSignerModalToggle}
              handleReNotify={this.handleReNotify}
              editSignerVisible={editSignerVisible}
              signerDetailsVisible={signerDetailsVisible}
              sortedFilteredColumns={sortedFilteredColumns}
              onSignerDetailsToggle={this.onSignerDetailsToggle}
              openDecryptModal={this.handleOpenDecryptionModal}
              trackDownloadFile={trackDownloadFile}
              onSignNow={this.handleSignNow}
            />
          </SidebarDetails>
        )}
      </Sidebar>
    );
  }
}
